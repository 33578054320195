import React from 'react'
import moment from 'moment'
import * as yup from 'yup'
import * as Sentry from '@sentry/react'

import * as Form from 'spd-oa/components/FormElement'
import { Choose, Icons } from 'spd-oa/components/common'
import Utils from 'spd-oa/utils'
import { Tooltip } from './components/common'

require('moment-timezone')

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function titleCase(str) {
  str = str.split(' ')
  for (let i = 0; i < str.length; i++) {
    if (/^[a-zA-Z]/.test(str[i])) {
      str[i] = str[i].toLowerCase()
    }
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

function hasEmpty(obj, expKey = null) {
  let objValues = { ...obj }
  for (var key in objValues) {
    if (expKey !== null) {
      if (expKey.includes(key)) {
        delete objValues[key]
      }
    }
    if (objValues[key] === '') {
      return true
    }
  }
  return false
}

function isEmpty(obj) {
  for (var prop in obj) {
    return false
  }
  return true
}

function allEmpty(obj) {
  for (var prop in obj) {
    if (obj[prop]) return false
  }
  return true
}

function titleize(str, separator) {
  let sepRegex = new RegExp(separator, 'g')
  return titleCase(
    str.replace(sepRegex, ' ')
    // .replace(/([A-Z]+)/g, ' $1')
    // .replace(/([A-Z][a-z])/g, ' $1')
  )
}

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

function arrayUnique(array) {
  var a = array.concat()
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}

function checkIfValIsNum(v) {
  return Number.isInteger(parseInt(v))
}

function transformWithZero(v, padOffset) {
  if (v.length < 2) {
    if (v.charAt(0) !== '0' && checkIfValIsNum(v.charAt(0))) {
      return `0${v}`
    } else {
      return v.slice(padOffset)
    }
  }
  if (v.length > 2) {
    if (v.charAt(0) === '0' && checkIfValIsNum(v.charAt(0))) {
      let sliceIt = true
      for (let i = 1; i < v.length; i++) {
        if (v.charAt(i) && !checkIfValIsNum(v.charAt(i))) {
          sliceIt = false
        }
      }
      if (sliceIt) {
        return v.slice(padOffset + 1)
      }
      return v
    }
  }
  return v
}

function trailWithZero(v, joining = '/') {
  let padOffset = -v.length
  if (v === '') {
    return ''
  }
  let vJoin = v.split(joining)
  if (vJoin.length > 1) {
    let vSplit = ''
    vJoin.forEach((vJ, vIndex) => {
      let r = transformWithZero(vJ, padOffset)
      if (r) {
        vSplit += `${r}`
      }
      if (vIndex < vJoin.length - 1) {
        vSplit += `/`
      }
    })
    return vSplit
  }

  let res = transformWithZero(v, padOffset)
  if (res) {
    return res
  }
  return v.slice(padOffset)
}

function parseDate(date, format = 'DD MMM YYYY', timezone = 'Asia/Singapore') {
  if (timezone) {
    return moment(date)
      .tz(timezone)
      .format(format)
  }
  return moment(date).format(format)
}

function isCitizenOrPR(nric_fin) {
  if (nric_fin === '') return false
  const res = nric_fin.charAt(0) === 'S' || nric_fin.charAt(0) === 'T'
  return res
}

// Based on http://www.samliew.com/icval/
function validateNRIC(str) {
  if (!str || str.length !== 9) return false

  str = str.toUpperCase()

  let i,
    icArray = []
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i)
  }

  icArray[1] = parseInt(icArray[1], 10) * 2
  icArray[2] = parseInt(icArray[2], 10) * 7
  icArray[3] = parseInt(icArray[3], 10) * 6
  icArray[4] = parseInt(icArray[4], 10) * 5
  icArray[5] = parseInt(icArray[5], 10) * 4
  icArray[6] = parseInt(icArray[6], 10) * 3
  icArray[7] = parseInt(icArray[7], 10) * 2

  let weight = 0
  for (i = 1; i < 8; i++) {
    weight += icArray[i]
  }
  let offset

  if (icArray[0] === 'T' || icArray[0] === 'G') {
    offset = 4
  } else if (icArray[0] === 'M') {
    offset = 3
  } else {
    offset = 0
  }

  let temp = (offset + weight) % 11

  let st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
  let fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K']
  let m = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'J', 'L', 'K']

  let theAlpha
  if (icArray[0] === 'S' || icArray[0] === 'T') {
    theAlpha = st[temp]
  } else if (icArray[0] === 'F' || icArray[0] === 'G') {
    theAlpha = fg[temp]
  } else if (icArray[0] === 'M') {
    theAlpha = m[temp]
  }

  return icArray[8] === theAlpha
}

// https://www.uen.gov.sg/ueninternet/faces/pages/admin/aboutUEN.jspx
// https://gist.github.com/mervintankw/90d5660c6ab03a83ddf77fa8199a0e52
function validateUEN(uen) {
  let debug = false
  const entityTypeIndicator = [
    'LP',
    'LL',
    'FC',
    'PF',
    'RF',
    'MQ',
    'MM',
    'NB',
    'CC',
    'CS',
    'MB',
    'FM',
    'GS',
    'GA',
    'GB',
    'DP',
    'CP',
    'NR',
    'CM',
    'CD',
    'MD',
    'HS',
    'VH',
    'CH',
    'MH',
    'CL',
    'XL',
    'CX',
    'RP',
    'TU',
    'TC',
    'FB',
    'FN',
    'PA',
    'PB',
    'SS',
    'MC',
    'SM',
  ]

  if (debug) {
    console.log('(A) Businesses registered with ACRA')
    console.log('(B) Local companies registered with ACRA')
    console.log('(C) All other entities which will be issued new UEN')
  }

  // check that uen is not empty
  if (!uen || String(uen) === '') {
    if (debug) {
      console.log('UEN is empty')
    }
    return false
  }

  // check if uen is 9 or 10 digits
  if (uen.length < 9 || uen.length > 10) {
    if (debug) {
      console.log('UEN is not 9 or 10 digits')
    }
    return false
  }

  uen = uen.toUpperCase()
  let uenStrArray = uen.split('')

  // (A) Businesses registered with ACRA
  if (uenStrArray.length === 9) {
    // check that last character is a letter
    if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
      if (debug) {
        console.log('(A) last character is not an alphabet')
      }
      return false
    }

    for (let i = 0; i < uenStrArray.length - 1; i++) {
      // check that first 8 letters are all numbers
      if (isNaN(uenStrArray[i])) {
        if (debug) {
          console.log('(A) there are non-numbers in 1st to 8th letters')
        }
        return false
      }
    }

    // (A) Businesses registered with ACRA (SUCCESS)
    if (debug) {
      console.log('valid (A) Businesses registered with ACRA')
    }
    return true
  } else if (uenStrArray.length === 10) {
    // check that last character is a letter
    if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
      if (debug) {
        console.log('(B)(C) last character is not an alphabet')
      }
      return false
    }

    // (B) Local companies registered with ACRA
    if (
      !isNaN(uenStrArray[0]) &&
      !isNaN(uenStrArray[1]) &&
      !isNaN(uenStrArray[2]) &&
      !isNaN(uenStrArray[3])
    ) {
      // check that 5th to 9th letters are all numbers
      if (
        !isNaN(uenStrArray[4]) &&
        !isNaN(uenStrArray[5]) &&
        !isNaN(uenStrArray[6]) &&
        !isNaN(uenStrArray[7]) &&
        !isNaN(uenStrArray[8])
      ) {
        // (B) Local companies registered with ACRA (SUCCESS)
        if (debug) {
          console.log('valid (B) Local companies registered with ACRA')
        }
        return true
      } else {
        if (debug) {
          console.log('(B) there are non-numbers in 5th to 9th letters')
        }
        return false
      }
    }
    // (C) All other entities which will be issued new UEN
    else {
      // check that 1st letter is either T or S or R
      if (
        uenStrArray[0] !== 'T' &&
        uenStrArray[0] !== 'S' &&
        uenStrArray[0] !== 'R'
      ) {
        if (debug) {
          console.log('(C) 1st letter is incorrect')
        }
        return false
      }

      // check that 2nd and 3rd letters are numbers only
      if (isNaN(uenStrArray[1]) || isNaN(uenStrArray[2])) {
        if (debug) {
          console.log('(C) 2nd and 3rd letter is incorrect')
        }
        return false
      }

      // check that 4th letter is an alphabet
      if (!isNaN(uenStrArray[3])) {
        if (debug) {
          console.log('(C) 4th letter is not an alphabet')
        }
        return false
      }

      // check entity-type indicator
      let entityTypeMatch = false,
        entityType = String(uenStrArray[3]) + String(uenStrArray[4])
      for (let i = 0; i < entityTypeIndicator.length; i++) {
        if (String(entityTypeIndicator[i]) === String(entityType)) {
          entityTypeMatch = true
        }
      }
      if (!entityTypeMatch) {
        if (debug) {
          console.log('(C) entity-type indicator is invalid')
        }
        return false
      }

      // check that 6th to 9th letters are numbers only
      if (
        isNaN(uenStrArray[5]) ||
        isNaN(uenStrArray[6]) ||
        isNaN(uenStrArray[7]) ||
        isNaN(uenStrArray[8])
      ) {
        if (debug) {
          console.log('(C) 2nd and 3rd letter is incorrect')
        }
        return false
      }

      // (C) All other entities which will be issued new UEN (SUCCESS)
      if (debug) {
        console.log('valid (C) All other entities which will be issued new UEN')
      }
      return true
    }
  }

  return false
}

function openLink(link, target) {
  if (!link) return
  window.open(link, target)
}

function processQueries(search, options) {
  if (search) {
    let queries = Utils.parseQueryParams(search)
    if (queries) {
      if (options) {
        options.forEach((opt) => {
          if (queries[opt.param]) {
            if (opt.value) {
              if (queries[opt.param] === opt.value) {
                if (typeof opt.resolver === 'function') {
                  opt.resolver(queries[opt.param], queries)
                }
              }
            } else {
              if (typeof opt.resolver === 'function') {
                opt.resolver(queries[opt.param], queries)
              }
            }
          }
        })
      }
    }
  }
}

const generateSchemaValidator = (validationType, validations) => {
  if (!yup[validationType]) {
    return null
  }
  let validator = yup[validationType]()
  validations.forEach((validation) => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    if (typeof params[0] === 'object') {
      if (params[0].type === 'ref') {
        let ref = params[0]
        let _params = params.slice(1, params.length)
        _params.unshift(yup[ref.type](...ref.params))
        validator = validator[type](..._params)
      } else if (params[0].type === 'when' || type === 'when') {
        let when = params[0]

        if (!when.target && !when.is && !when.then && !when.otherwise) {
          console.error(
            `Check the object param of validation type WHEN, it should have .target, .is, .then and .otherwise`
          )
        } else {
          let isParam = when.is
          if (typeof isParam === 'object') {
            if (isParam.type === 'function') {
              const isFunc = (value) => {
                if (isParam.condition === 'NON_ZERO') {
                  return parseInt(value) > 0
                }
                return false
              }
              isParam = isFunc
            }
          }
          let whenParams = [
            when.target,
            {
              is: isParam,
              then: generateSchemaValidator(
                when.then.validationType,
                when.then.validations
              ),
              otherwise: generateSchemaValidator(
                when.otherwise.validationType,
                when.otherwise.validations
              ),
            },
          ]
          validator = validator['when'](...whenParams)
        }
      }
    } else {
      validator = validator[type](...params)
    }
  })

  return validator
}

const getFormFields = (
  fields = [],
  formProps = {},
  options = {},
  tooltips = {}
) => {
  if (fields.length === 0) {
    return null
  }

  const shouldDisplay = (parentId, condition, parentValues) => {
    const { values } = formProps
    switch (condition) {
      case 'ANSWER_OPTION_EQ': {
        return parentValues[0].toLowerCase() === values[parentId].toLowerCase()
      }
      case 'VALUES_EITHER': {
        if (Array.isArray(values[parentId])) {
          return parentValues.some((opt) => values[parentId].includes(opt))
        }
        return parentValues.some((opt) => opt === values[parentId])
      }
      case 'NON_ZERO': {
        return parseInt(values[parentId]) > 0
      }
      case 'EXISTS': {
        return !!values[parentId]
      }
      default: {
        return false
      }
    }
  }

  const shouldDisplayField = (field) => {
    if (!field.parentID) return true

    const condition = field.parentCondition
    const parentId = field.parentID
    const parentValues = field.parentValues

    if (options.additionalCriteria) {
      if (
        options.additionalCriteria[field.id] &&
        options.additionalCriteria[field.id].length > 0
      ) {
        const criteriaNotFulfilled = options.additionalCriteria[field.id].some(
          (ac) => {
            return !shouldDisplay(
              ac.parentID,
              ac.parentCondition,
              ac.parentValues
            )
          }
        )
        if (criteriaNotFulfilled) {
          return false
        }
      }
    }

    return shouldDisplay(parentId, condition, parentValues)
  }

  const checkIfFieldIsRequired = (field) => {
    if (field.validations && Array.isArray(field.validations)) {
      if (field.validations.length > 0) {
        let validations = field.validations
        if (
          Array.isArray(field.validations[0]) &&
          field.validations[0].length > 0
        ) {
          validations = field.validations[0]
        }
        return validations.some((v) => v.type === 'required')
      }
    }
    return field.mandatory
  }

  return (
    <Form.FormGroup groupTitle={fields.groupTitle}>
      {fields.map((field, fieldKey) => {
        const isFieldRequired = checkIfFieldIsRequired(field)
        const inputAttr = field.inputAttr || {}
        let fieldLabel = field.label || field.question
        if (!isFieldRequired) {
          fieldLabel = (
            <>
              {fieldLabel} <br />
              <i>(optional)</i>
            </>
          )
        }
        if (tooltips[field.id]) {
          fieldLabel = (
            <>
              {fieldLabel}
              <Tooltip>{tooltips[field.id]}</Tooltip>
            </>
          )
        }

        if (!field.type) {
          if (field.content) {
            return (
              <Form.FormField
                key={`${field.id}_${fieldKey}`}
                field="field-item--content"
                label={fieldLabel}
              >
                <p>{field.content}</p>
              </Form.FormField>
            )
          }
        }

        if (field.type === 'CHECKBOX' || field.type === 'CHECKBOX_ALL') {
          if (shouldDisplayField(field, formProps.values)) {
            return (
              <Form.FormChoose
                key={`${field.id}_${fieldKey}`}
                id={field.id}
                title={fieldLabel}
                onlyElem={false}
                selected={formProps.values[field.id]}
                error={
                  formProps.touched[field.id] && formProps.errors[field.id]
                }
                setFieldValue={formProps.setFieldValue}
                setFieldTouched={formProps.setFieldTouched}
                // handleChange={formProps.handleChange}
                choices={field.choices || field.answerOptions}
                multiple
                chooseAll={field.chooseAll || field.type === 'CHECKBOX_ALL'}
              />
            )
          }
        }
        if (field.type === 'SELECT') {
          if (shouldDisplayField(field, formProps.values)) {
            let selectOptions = field.options
            if (!selectOptions) {
              selectOptions = field.answerOptions
            }
            return (
              <Form.FormSelectOption
                key={`${field.id}_${fieldKey}`}
                id={field.id}
                name={field.id}
                label={fieldLabel}
                note={field.note || field.description}
                error={
                  formProps.touched[field.id] && formProps.errors[field.id]
                }
                value={formProps.values[field.id]}
                options={[
                  {
                    value: '',
                    text: 'Select One',
                  },
                  ...selectOptions,
                ]}
                onChange={formProps.handleChange}
                handleBlur={formProps.handleBlur}
                disabled={field.disabled || false}
              />
            )
          }
        }
        if (field.type === 'SINGLE_CHOICE') {
          if (shouldDisplayField(field, formProps.values)) {
            const { defaultValue } = options
            return (
              <Form.FormField
                key={`${field.id}_${fieldKey}`}
                field="field-item--singlechoice"
                label={field.label}
              >
                <Choose.Choices
                  id={field.id}
                  defaultSelected={defaultValue || field.defaults}
                  onChangeHandler={formProps.setFieldValue}
                  lengthwise
                >
                  {field.options &&
                    field.options.length > 0 &&
                    field.options.map((opt, optIdx) => (
                      <Choose.Choice
                        key={`single-choice--${field.id}-${optIdx}`}
                        id={opt.id}
                        className={opt.icon && 'with-icon'}
                        title={
                          <>
                            {opt.icon && (
                              <img
                                className="cui-choice__btn__icon"
                                src={opt.icon}
                                alt={`${opt.text || opt.value} icon`}
                              />
                            )}
                            {opt.text ||
                              (opt.value && <h4>{opt.text || opt.value}</h4>)}
                            {opt.description && (
                              <small>{opt.description}</small>
                            )}
                          </>
                        }
                      />
                    ))}
                </Choose.Choices>
              </Form.FormField>
            )
          }
        }

        if (shouldDisplayField(field, formProps.values)) {
          let maxLength = ''
          let minLength = ''
          let onlyNumber = field.onlyNumber
          let trailZero = field.trailZero

          if (!onlyNumber) {
            if (field.type === 'INTEGER') {
              onlyNumber = {
                integer: true,
              }
            }
          }

          if (field.validations) {
            let max = field.validations.filter((v) => v.type === 'max')
            if (max.length > 0) {
              maxLength = max[0].params[0]
            }

            let min = field.validations.filter((v) => v.type === 'min')
            if (min.length > 0) {
              minLength = min[0].params[0]
            }
          }

          return (
            <Form.FormInput
              key={`${field.id}_${fieldKey}`}
              id={field.id}
              label={fieldLabel}
              name={field.name || field.id}
              className={field.className}
              error={formProps.touched[field.id] && formProps.errors[field.id]}
              value={formProps.values[field.id]}
              maxLength={maxLength}
              minLength={minLength}
              onlyNumber={onlyNumber || false}
              trailZero={trailZero || false}
              capsOnChange={field.capsOnChange || false}
              setFieldValue={formProps.setFieldValue}
              disabled={field.disabled || false}
              handleBlur={formProps.handleBlur}
              unit={field.unit || field.answerUnit}
              info={field.info}
              note={field.description}
              placeholder={field.placeholder}
              type={'text'}
              showModified={field.showModified || false}
              handleChange={(e) => formProps.handleChange(e)}
              {...inputAttr}
            >
              {field.content && (
                <div dangerouslySetInnerHTML={{ __html: field.content }} />
              )}
            </Form.FormInput>
          )
        }
      })}
    </Form.FormGroup>
  )
}

const tensionErrorContent = (removeElect) => {
  const { mode: oaMode } = Utils.getOAMode()

  return (
    <>
      <p>
        Effective DD MM 20YY, under the Electricity (Contestable Consumers)
        Regulations 20YY, a commercial consumer that is not Management
        Corporation Strata Title (MCST) taking electricity supply at the High
        Tension (HT) and above level is classified as a contestable consumer. A
        contestable consumer is required to purchase electricity from the Open
        Electricity Market. A non-contestable consumer may purchase electricity
        at the regulated tariff to which you are not eligible.
      </p>
      <br />

      <p>
        You may source for a suitable retail contract with any electricity
        retailer on the Open Electricity Market. Please visit{' '}
        {oaMode !== 'kiosk' ? (
          <a
            href="https://www.openelectricitymarket.sg/business/list-of-retailers"
            target="_blank"
          >
            Choose Electricity Retailers for Business | Open Electricity Market
          </a>
        ) : (
          'Choose Electricity Retailers for Business | Open Electricity Market'
        )}{' '}
        for more information.
      </p>
      <br />

      <p>
        Alternatively, if you wished to purchase electricity from the Wholesale
        Electricity Market through SP Group, please submit the completed
        ‘Application for Contestability Status & Market Support Services (“MSS”)
        Account (Including Transmission Services for Low Tension (“LT”)
        Consumers)’ form and supporting documents to us. This form can be found
        on the Open Electricity Market website. Please note that the electricity
        rate you pay will fluctuate. The rate will vary every half hour
        depending on the prevailing demand and supply situation in the wholesale
        electricity market.
      </p>
      <br />
      {removeElect && (
        <p>
          Please note that Electricity at HT and above level will be removed
          from this application. You may proceed with the other supplies type
          selected.
        </p>
      )}
    </>
  )
}

const getElectCapacityRangeIdx = ({ ELECT_CAPACITY } = {}) => {
  if (!ELECT_CAPACITY) return null
  const electCapacityRange = ELECT_CAPACITY.split('_')[3] || 0 // Extracting the numeric value eg. from ELECT_SUPPLY_CAPACITY_3
  return parseInt(electCapacityRange)
}

const getGaIdByLinkerParam = () => {
  let gaId = ''
  if (window.ga && window.ga.getAll) {
    gaId = window.ga.getAll()[0].get('linkerParam')
    if (gaId) {
      gaId = gaId.split('=')[1]
    } else {
      gaId = '00000'
    }
  } else {
    gaId = '00000'
  }
  return gaId
}

const dollarToCents = (amount) => {
  if (typeof amount !== 'number') {
    throw new Error('Amount passed must be of type Number.')
  }
  return Math.round(100 * amount)
}

/**
 * format to Singapore dollar
 * @param amount {Number} >= 0
 */
function formatSGD(amount) {
  return `S$ ${amount.toLocaleString()}`
}

let errSentryCount = 0

const reportToSentry = (err, payload) => {
  Sentry.withScope((scope) => {
    if (payload) {
      scope.setExtra('Input Request', payload)
    }
    scope.setTag('module', 'FE')
    scope.setTag('service', 'OA')
    if (err.message) {
      Sentry.captureMessage(`Error ${errSentryCount}. ${err.message}`)
    } else {
      Sentry.captureMessage(`Error ${errSentryCount}. An error occurred`)
    }
    errSentryCount++
  })
}

const mappingTensionLevel = {
  LT: 'Low Tension',
  HT: 'High Tension',
  EHT: 'Extra High Tension',
  UHT: 'Ultra High Tension',
}

export {
  capitalize,
  hasEmpty,
  titleize,
  titleCase,
  getWindowSize,
  arrayUnique,
  isEmpty,
  trailWithZero,
  parseDate,
  isCitizenOrPR,
  validateNRIC,
  validateUEN,
  allEmpty,
  checkIfValIsNum,
  openLink,
  processQueries,
  getFormFields,
  getElectCapacityRangeIdx,
  tensionErrorContent,
  getGaIdByLinkerParam,
  dollarToCents,
  formatSGD,
  reportToSentry,
  mappingTensionLevel,
}
