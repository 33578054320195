import React, { PureComponent, Fragment } from 'react'
import { Formik } from 'formik'
import { object, string, boolean } from 'yup'

import {
  FormGroup,
  FormInput,
  FormMobileNumber,
  FormCTA,
} from 'spd-oa/components/FormElement'
import { WizError } from 'spd-oa/components/wiz'
import {
  Button,
  Icons,
  SuccessBar,
  MyInfoTerms,
  ConfirmationDialog,
} from 'spd-oa/components/common'
import { MyInfoBiz } from 'spd-oa/components/myinfo'
import { hasEmpty, isEmpty, validateNRIC, validateUEN } from 'spd-oa/helpers'
import debounce from 'lodash/debounce'
import { API } from 'spd-oa/services'
import Utils from 'spd-oa/utils'

import MyInfoLogoBiz from 'spd-oa/images/logos/myinfo-biz.svg'
import { tensionErrorContent } from '../../helpers'

const emailRegex = /^\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\s*$/
const schema = object().shape({
  uen: string()
    .when(['bypassUen', 'isValidCompany'], {
      is: (bypassUen, isValidCompany) => bypassUen || isValidCompany,
      then: string().required(),
      otherwise: string()
        .test('uen', 'Please enter valid UEN', (val) => {
          return validateUEN(val)
        })
        .required(),
    })
    .label('UEN'),
  bypassUen: boolean(),
  company_name: string()
    .required()
    .strict(false)
    .trim('Please enter valid company name')
    .matches(/^[\x20-\x7E]*$/, 'Please enter valid company name')
    .label('Company Name')
    .max(80),
  representative_name: string()
    .required()
    .strict(false)
    .trim('Please enter valid name')
    .matches(/^[a-zA-Z,()/\-.'@\s]*$/, 'Please enter valid name')
    .label('Representative Name')
    .max(40),
  representative_designation: string()
    .required()
    .strict(false)
    .trim('Please enter valid Representative Designation')
    .label('Representative Designation'),
  representative_email: string()
    .matches(emailRegex, 'Please enter valid email address')
    .email('Please enter valid email address')
    .max(64, 'Maximum of 64 characters are allowed for Email address')
    .required()
    .label('Representative Email'),
  representative_mobile_number: string()
    .required()
    .min(8, 'Representative Mobile Number must be 8 digits')
    .max(8, 'Representative Mobile Number must be 8 digits')
    .matches(/^([89]\d{7})$/, 'Please enter valid Representative mobile number')
    .label('Representative Mobile Number'),
  representative_contact_number: string()
    .min(8, 'Representative Alternate Contact Number must be 8 digits')
    .max(8, 'Representative Alternate Contact Number must be 8 digits')
    .matches(
      /^([9863]\d{7})$/,
      'Please enter valid Representative Alternate Contact Number'
    )
    .test(
      'contacts-match',
      'Please provide a different contact number',
      function(value) {
        return this.parent.representative_mobile_number !== value
      }
    )
    .label('Representative Alternate Contact Number'),
  representative_alternate_email: string()
    .matches(emailRegex, 'Please enter valid email address')
    .email('Please enter valid email address')
    .max(64, 'Maximum of 64 characters are allowed for Email address')
    .test('email-match', 'Please provide a different email address', function(
      value
    ) {
      return this.parent.representative_email !== value
    }),
})

const acraDetailError =
  'Company status is invalid. Please enter an active company UEN.'

class Company extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const data = context.actions.hydrate({
      company_information: {
        uen: '',
        company_name: '',
      },
      representative: {
        representative_name: '',
        representative_designation: '',
        representative_nric_fin: '',
        representative_email: '',
        representative_alternate_email: '',
        representative_mobile_prefix: '+65',
        representative_mobile_number: '',
        representative_contact_prefix: '+65',
        representative_contact_number: '',
      },
      use_uen: false,
      use_myinfo: false,
      promptMyInfo: true,
      showManualEntryPrompt: true,
    })

    context.actions.setFields(data)
    this.state = {
      data,
      processingMyInfo: false,
      myinfoDataError: null,
      myinfoDataErrorPop: false,
      myinfoDataFetching: false,
      myinfoDataSuccess: false,
      myinfoDataNameEnable: false,
      myinfoDataCompanyStatusError: null,
      myinfoDataCompanyStatusErrorPop: false,
      showMyInfoTnc: false,
      // showManualEntryPrompt: true,
      companyNameError: null,
      isCompanyInactive: false,
      companyName: {
        block: false,
        showError: false,
        isValidCompany: false,
        status: null,
        loading: false,
      },
      prevVal: this.props.context.data.company_information
        ? this.props.context.data.company_information.uen
        : '',
      query: this.props.context.data.company_information
        ? this.props.context.data.company_information.uen
        : '',
      electWithTension: false,
      onlyElectWithTension: false,
    }
    this.fetchPlaces = debounce(this.fetchCompanyName, 200, {
      leading: false,
      trailing: true,
    })
  }

  componentDidMount() {
    const { data } = this.state
    const {
      context: { actions },
    } = this.props
    actions.setFields(data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search) {
      let queries = Utils.parseQueryParams(this.props.location.search)
      if (queries && queries['redirected']) {
        if (queries['redirected'] === 'myinfo') {
          this._getMyInfoData()
        }
      }
    }

    if (
      this.props.context.data.promptMyInfo !==
      prevProps.context.data.promptMyInfo
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { mode } = Utils.getOAMode()
    const { context } = this.props
    const { data: contextData } = context
    const {
      data,
      companyName,
      companyNameError,
      processingMyInfo,
      myinfoDataFetching,
      myinfoDataSuccess,
      myinfoDataError,
      myinfoDataErrorPop,
      myinfoDataCompanyStatusError,
      myinfoDataCompanyStatusErrorPop,
      showMyInfoTnc,
      isCompanyInactive,
      showManualEntryPrompt,
      onlyElectWithTension,
      electWithTension,
    } = this.state
    return (
      <div className={`signup--company signup--form`}>
        <Formik
          ref={(el) => (this._form = el)}
          validationSchema={schema}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={false}
          initialValues={{
            ...data.company_information,
            ...data.representative,
            isValidCompany: this.state.companyName.isValidCompany,
            use_myinfo: data.use_myinfo,
            bypassUen: contextData.bypassUen,
          }}
          onSubmit={this._handleSubmit}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
            isInitialValid,
            isValid,
          }) => {
            return contextData.promptMyInfo &&
              !values.use_myinfo &&
              !myinfoDataFetching ? (
              <>
                {(mode === 'mobile' || mode === 'kiosk') && myinfoDataError && (
                  <WizError
                    error={
                      <span>
                        An error has occurred while using Myinfo Business.
                        Please try again later.
                      </span>
                    }
                  />
                )}
                <MyInfoBiz
                  contextConfig={context.config}
                  confirmHandler={this._confirmMyInfoHandler}
                  skipHandler={this._skipMyInfoHandler}
                  backHandler={this._handleBackBtn}
                  processing={processingMyInfo}
                  business={true}
                />
              </>
            ) : (
              <form
                className="form-container"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onSubmit={handleSubmit}
              >
                {myinfoDataError && !myinfoDataErrorPop && (
                  <WizError error={myinfoDataError} />
                )}
                {myinfoDataFetching && (
                  <section className="form-cover">
                    <div className="cover-content">
                      <Icons.spinloader
                        width={50}
                        height={50}
                        color={['#333333']}
                      />
                      <span>Processing..</span>
                    </div>
                  </section>
                )}
                {!values.use_myinfo &&
                  !myinfoDataFetching &&
                  !myinfoDataError && (
                    <MyInfoBiz
                      isField={true}
                      confirmHandler={this._confirmMyInfoHandler}
                      skipHandler={this._skipMyInfoHandler}
                      business={true}
                    />
                  )}
                {myinfoDataSuccess && (
                  <SuccessBar>
                    <span>
                      {`You have successfully retrieved your company information using `}
                    </span>
                    <img
                      className="myinfo-logo"
                      src={MyInfoLogoBiz}
                      alt="Myinfo"
                    />
                  </SuccessBar>
                )}
                <FormGroup groupTitle="Your Company">
                  <FormInput
                    label="UEN"
                    id="uen"
                    name="uen"
                    className={'upper'}
                    error={touched.uen && errors.uen}
                    showError={
                      !contextData.bypassUen && touched.uen && errors.uen
                    }
                    value={values.uen}
                    capsOnChange={true}
                    setFieldValue={setFieldValue}
                    // handleChange={handleChange}
                    handleBlur={this.onSearchChange}
                    onPointerDown={this.setVal}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.onSearchChange(ev) && ev.preventDefault()
                      }
                    }}
                    autoComplete={'off'}
                    disabled={values.use_myinfo}
                  />
                  <FormInput
                    label="Company Name"
                    id="company_name"
                    name="company_name"
                    error={
                      companyName.status === 'error'
                        ? companyNameError
                        : touched.company_name && errors.company_name
                    }
                    value={values.company_name}
                    capsOnChange={true}
                    setFieldValue={setFieldValue}
                    disabled={values.use_myinfo || contextData.use_uen}
                    handleBlur={this._handleBlurInput}
                    loading={companyName.loading}
                    showError={
                      companyName.showError && companyName.status === 'error'
                    }
                    maxLength={'80'}
                  />
                </FormGroup>
                <FormGroup
                  groupTitle="Your Company Representative"
                  groupHeaderContent={
                    !values.use_myinfo ? (
                      <Fragment>
                        <p>
                          {`If you are representing the company to apply for the utilities account, you need to sign on the application form before submission.`}
                        </p>
                        <p>
                          {`If you are not the company's director, you need to submit a letter of authorisation.`}
                        </p>
                      </Fragment>
                    ) : null
                  }
                >
                  <FormInput
                    label="Name"
                    id="representative_name"
                    name="representative_name"
                    error={
                      touched.representative_name && errors.representative_name
                    }
                    value={values.representative_name}
                    capsOnChange={true}
                    setFieldValue={setFieldValue}
                    handleBlur={this._handleBlurInput}
                    maxLength={'40'}
                    disabled={values.use_myinfo}
                  />
                  <FormInput
                    label="Designation"
                    id="representative_designation"
                    name="representative_designation"
                    error={
                      touched.representative_designation &&
                      errors.representative_designation
                    }
                    value={values.representative_designation}
                    handleBlur={this._handleBlurInput}
                    capsOnChange={true}
                    setFieldValue={setFieldValue}
                  />
                  <FormMobileNumber
                    label="Mobile No"
                    id="representative_mobile"
                    errorPrefix={
                      (touched.representative_mobile_prefix &&
                        errors.representative_mobile_prefix) ||
                      errors.representative_mobile_prefix
                    }
                    errorNumber={
                      (touched.representative_mobile_number &&
                        errors.representative_mobile_number) ||
                      errors.representative_mobile_number
                    }
                    valuePrefix={values.representative_mobile_prefix}
                    value={values.representative_mobile_number}
                    handleChange={handleChange}
                  />
                  <FormMobileNumber
                    label="Alternate Contact No (Optional)"
                    id="representative_contact"
                    errorPrefix={
                      (touched.representative_contact_prefix &&
                        errors.representative_contact_prefix) ||
                      errors.representative_contact_prefix
                    }
                    errorNumber={
                      (touched.representative_contact_number &&
                        errors.representative_contact_number) ||
                      errors.representative_contact_number
                    }
                    valuePrefix={values.representative_contact_prefix}
                    value={values.representative_contact_number}
                    handleChange={handleChange}
                  />
                  <FormInput
                    label="Email"
                    id="representative_email"
                    name="representative_email"
                    error={
                      touched.representative_email &&
                      errors.representative_email
                    }
                    value={values.representative_email}
                    handleBlur={this._handleBlurInput}
                    handleChange={handleChange}
                  />
                </FormGroup>
                <FormCTA>
                  <Button
                    variant="secondary"
                    onClickHandler={this._handleBackBtn}
                  >
                    {`Back`}
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={this._isSubmitDisabled(
                      errors,
                      isInitialValid,
                      isValid
                    )}
                  >
                    {`Next`}
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />

        {onlyElectWithTension && (
          <ConfirmationDialog
            id="only-elect-tension-err-prompt"
            confirmCancelHandler={this._myinfoErrHandler}
            content={tensionErrorContent()}
            processing={false}
            actionConfig={{
              cancel: {
                label: 'Go back to open account screen',
              },
              ok: false,
            }}
          />
        )}
        {electWithTension && (
          <ConfirmationDialog
            id="elect-tension-err-prompt"
            confirmOkHandler={this._removeElectrictWithTension}
            content={tensionErrorContent(true)}
            processing={false}
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
        {!contextData.promptMyInfo &&
          !contextData.use_myinfo &&
          contextData.showManualEntryPrompt && (
            <ConfirmationDialog
              id="myinfo-prompt"
              processing={false}
              content={
                <div>
                  <h3>
                    Please have the following documents ready to proceed with
                    the application.
                  </h3>
                  <ul>
                    <li>ACRA (Soft copy)</li>
                    <li>
                      Letter of Authorisation (Soft copy. Required if you are
                      not the Director/Owner)
                    </li>
                    {mode !== 'kiosk' && (
                      <li>
                        You will be required to print, sign and attach the
                        application form in subsequent step. Please ensure you
                        have a printer ready on hand.
                      </li>
                    )}
                  </ul>
                </div>
              }
              confirmOkHandler={this._confirmMyInfoHandler}
              confirmCancelHandler={this._manualEntryPromptCancelHandler}
              actionConfig={{
                cancel: {
                  label: 'OK, proceed to fill up form',
                  styles: {
                    background: 'transparent',
                    color: '#00b0b2',
                    border: '1px solid #00b0b2',
                  },
                },
                ok: {
                  label: 'Use Myinfo Business',
                },
              }}
            />
          )}
        {myinfoDataError && myinfoDataErrorPop && (
          <ConfirmationDialog
            id="myinfo-err-prompt"
            confirmOkHandler={this._myinfoErrContinueHandler}
            content={`${myinfoDataError}`}
            processing={false}
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
        {myinfoDataCompanyStatusError && myinfoDataCompanyStatusErrorPop && (
          <ConfirmationDialog
            id="myinfo-err-prompt"
            confirmCancelHandler={this._myinfoErrHandler}
            content={`${myinfoDataCompanyStatusError}`}
            processing={false}
            actionConfig={{
              cancel: {
                label: 'Go back to open account screen',
              },
              ok: false,
            }}
          />
        )}
        {isCompanyInactive && (
          <ConfirmationDialog
            id="myinfo-err-prompt"
            confirmCancelHandler={this._cancelCompanyInactiveHandler}
            content={acraDetailError}
            processing={false}
            actionConfig={{
              cancel: {
                label: 'OK',
              },
              ok: false,
            }}
          />
        )}
        {showMyInfoTnc && (
          <ConfirmationDialog
            id="myinfo-tnc-prompt"
            confirmOkHandler={this._confirmMyInfoTermsConditions}
            confirmCancelHandler={this._cancelMyInfoTermsConditions}
            content={<MyInfoTerms business={true} />}
            actionConfig={{
              styles: {
                display: 'flex',
                flexDirection: 'column',
              },
              ok: {
                label: 'I want to use Myinfo business',
                styles: {
                  margin: 'auto',
                },
              },
              cancel: {
                label: 'Do not proceed',
                styles: {
                  display: 'block',
                  border: 'none',
                  background: 'none',
                  color: '#e54545',
                },
              },
            }}
          />
        )}
      </div>
    )
  }

  _isSubmitDisabled = (errors, isInitialValid, isValid) => {
    const {
      companyName: { loading },
    } = this.state
    const {
      context: { data: contextData },
    } = this.props
    const {
      hasActiveAcctUnderName,
      hasMissedAppointment,
      isDisconnected,
    } = contextData

    if (
      hasActiveAcctUnderName ||
      hasMissedAppointment ||
      isDisconnected ||
      loading
    ) {
      return true
    }
    if (!isEmpty(errors)) {
      if (isValid) {
        return false
      }
      const errKeys = Object.keys(errors)
      if (errKeys.length === 1) {
        if (errors.uen) {
          if (contextData.bypassUen) {
            return false
          }
        }
      }
      return true
    }
    if (isValid || isInitialValid) {
      return false
    }
    return false
  }

  fetchCompanyName(uen) {
    this.setState((prevState) => ({
      ...prevState,
      companyName: {
        ...prevState.companyName,
        status: 'success',
        loading: true,
      },
    }))
    this._form.setFieldValue('company_name', '')

    this._checkIfEligible(() => {
      this.retrieveCompanyName(uen)
    })
  }

  retrieveCompanyName = (uen) => {
    const { context } = this.props
    const { actions } = context
    API.retrieveCompanyName({
      uen,
    })
      .then((res) => {
        this.setState(
          (prevState) => ({
            ...prevState,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              companyName: {
                ...prevState.companyName,
                block: false,
                showError: false,
                status: 'success',
                isValidCompany: true,
                loading: false,
              },
            }))
            this._form.setFieldError('company_name', '')
            if (res.details === acraDetailError) {
              this.setState((prevState) => ({
                ...prevState,
                isCompanyInactive: true,
              }))
              actions.presave({
                company_information: {
                  uen: '',
                  company_name: '',
                  company_name_source: '',
                  ebs_company_name: '',
                },
                use_uen: false,
                bypassUen: false,
              })
              return
            }
            if (res.name === '') {
              this._form.setFieldValue('company_name', res.name)
              this._form.setFieldValue('company_name_source', res.source)
              this._form.setFieldValue('ebs_company_name', res.data.ebsName)
              this._form.setFieldValue('bypassUen', false)
              actions.presave({
                company_information: {
                  uen,
                  company_name: res.name,
                  company_name_source: res.source,
                  ebs_company_name: res.data.ebsName,
                },
                use_uen: false,
                bypassUen: false,
              })
            } else {
              const uenError = this._form.state.errors.hasOwnProperty('uen')
              this._form.setFieldError('uen', '')
              this._form.setFieldValue('bypassUen', uenError)
              this._form.setFieldValue('company_name', res.name)
              this._form.setFieldValue('company_name_source', res.source)
              this._form.setFieldValue('ebs_company_name', res.data.ebsName)
              this._form.setFieldValue('isValidCompany', true)
              actions.presave({
                company_information: {
                  uen,
                  company_name: res.name,
                  company_name_source: res.source,
                  ebs_company_name: res.data.ebsName,
                },
                use_uen: true,
                bypassUen: uenError,
              })
            }
          }
        )
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          companyName: {
            ...prevState.companyName,
            block: false,
            showError: true,
            status: 'error',
            loading: false,
          },
          companyNameError: err.message,
        }))
        this._form.setFieldValue('company_name', '')
      })
  }

  onSearchChange = (ev) => {
    const q = ev.target.value
    if (q !== this.prevVal || !this.state.companyName.loading) {
      this.fetchCompanyName(q)
    }
    this._form.setFieldTouched(ev.target.id, true)
    this.setState({ q: q, query: q })
    this.prevVal = q
  }

  setVal = (ev) => {
    const pv = ev.target.value
    this.setState({ prevVal: pv })
  }

  _handleBlurInput = (e) => {
    this._form.setFieldTouched(e.target.id, true)
  }

  _checkTensionValidation = (uen) => {
    const { context } = this.props
    const { config, actions: contextActions, data: contextData } = context
    const { values } = this._form.state
    const { turnOnServices } = contextData
    const electSelected =
      turnOnServices && turnOnServices.services.includes('electricity')
    const nric_roc = uen || values.uen

    if (
      config.application_domain === 'commercial' &&
      electSelected &&
      contextData.premises.tension &&
      contextData.premises.tension !== 'LT' &&
      nric_roc
    ) {
      const payload = {
        tensionLevel: contextData.premises.tension,
        nric: nric_roc,
        electricitySelected: electSelected,
        waterSelected:
          turnOnServices && turnOnServices.services.includes('water'),
        gasSelected: turnOnServices && turnOnServices.services.includes('gas'),
        chilledWaterSelected: turnOnServices && turnOnServices.chilled_water,
      }
      API.tensionValidation(payload)
        .then((res) => {
          contextActions.presave({
            mcst: res.mcst,
          })
          if (!res.mcst) {
            if (
              electSelected &&
              turnOnServices.services.length === 1 &&
              !turnOnServices.chilled_water
            ) {
              this.setState((prevState) => ({
                ...prevState,
                onlyElectWithTension: true,
              }))
            } else {
              this.setState((prevState) => ({
                ...prevState,
                electWithTension: true,
              }))
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  _removeElectrictWithTension = () => {
    const { actions: contextActions, data: contextData } = this.props.context
    const { services } = contextData.turnOnServices
    const nonElectServices = services.filter((sv) => sv !== 'electricity')
    this.setState((prevState) => ({
      ...prevState,
      electWithTension: false,
    }))
    contextActions.presave({
      turnOnServices: {
        ...contextData.turnOnServices,
        services: nonElectServices,
      },
      premises: { ...contextData.premises, tension: '' },
    })
  }

  _getMyInfoData = () => {
    const {
      context: { stateId, actions },
      history,
      match,
    } = this.props
    this.setState((prevState) => ({
      ...prevState,
      processingMyInfo: false,
      myinfoDataFetching: true,
    }))
    this._preSave({
      ...this._form.state.values,
      showManualEntryPrompt: false,
    })
    API.myInfoBiz
      .getData({
        stateId,
      })
      .then((res) => {
        const data = {
          uen: res.uen,
          company_name: res.company_name,
          company_name_source: 'myinfo',
          ebs_company_name: res.company_name,
          representative_name: res.representative_name,
          representative_email: res.representative_email,
          representative_mobile_number: res.representative_mobileno,
          use_myinfo: true,
        }
        this._setFields(data)
        this.setState((prevState) => ({
          ...prevState,
          myinfoDataFetching: false,
          myinfoDataSuccess: true,
          myinfoDataError: null,
          myinfoDataErrorPop: false,
          myinfoDataCompanyStatusError: null,
          myinfoDataCompanyStatusErrorPop: false,
          myinfoDataNameEnable: false,
          companyName: {
            ...prevState.companyName,
            status: 'success',
            loading: true,
          },
        }))
        this._checkIfEligible(() => {
          this.setState((prevState) => ({
            ...prevState,
            companyName: {
              ...prevState.companyName,
              status: 'success',
              loading: false,
            },
          }))
        }, res.uen)
        actions.presave({
          promptMyInfo: false,
          use_myinfo: true,
          use_uen: false,
          company_information: {
            uen: data.uen,
            company_name: data.company_name,
            company_name_source: 'myinfo',
            ebs_company_name: data.company_name,
          },
          representative: {
            representative_name: data.representative_name,
            representative_designation: data.representative_designation,
            representative_email: data.representative_email,
            representative_alternate_email: '',
            representative_mobile_prefix: '+65',
            representative_mobile_number: data.representative_mobile_number,
            representative_contact_prefix: '+65',
            representative_contact_number: '',
          },
          showManualEntryPrompt: false,
        })
        this._updateSignupFlow()
      })
      .catch((err) => {
        let _err = {
          myinfoDataFetching: false,
          myinfoDataSuccess: false,
          myinfoDataError: null,
          myinfoDataErrorPop: false,
          myinfoDataCompanyStatusError: null,
          myinfoDataCompanyStatusErrorPop: false,
          myinfoDataNameEnable: false,
        }
        if (err.error) {
          _err.myinfoDataError = (
            <span>
              An error has occured while using Myinfo Business.{' '}
              <Button link={true} onClickHandler={this._confirmMyInfoHandler}>
                Try again
              </Button>{' '}
              or fill up your company information below.
            </span>
          )
          _err.myinfoDataErrorPop = false
          _err.myinfoDataNameEnable = false
          _err.myinfoDataCompanyStatussErrorPop = false
          if (err.error === 'myinfobiz_companystatus_notlive') {
            _err.myinfoDataCompanyStatusErrorPop = true
            _err.myinfoDataCompanyStatusError = err.error_description
            actions.presave({
              use_myinfo: false,
              promptMyInfo: true,
            })
            this._setFields({
              use_myinfo: false,
              promptMyInfo: true,
            })
          } else {
            this._setFields({
              use_myinfo: false,
              promptMyInfo: false,
            })
          }
          this._form.setFieldValue('use_myinfo', false)
        }
        actions.presave({
          use_myinfo: false,
          promptMyInfo:
            Utils.getOAMode().mode === 'mobile' ||
            Utils.getOAMode().mode === 'kiosk',
          showManualEntryPrompt: false,
        })
        this.setState((prevState) => ({
          ...prevState,
          ..._err,
        }))
      })
    history.push(match.path)
  }

  _confirmMyInfoHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: true,
    }))
    this._preSave({
      ...this._form.state.values,
      showManualEntryPrompt: false,
    })
  }

  _skipMyInfoHandler = () => {
    const { context } = this.props
    const { actions } = context
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: false,
    }))
    this._form.setFieldValue('use_myinfo', false)
    actions.presave({
      promptMyInfo: false,
      use_myinfo: false,
      showManualEntryPrompt: true,
    })
  }

  _confirmMyInfoTermsConditions = () => {
    const {
      context: { stateId, actions },
    } = this.props
    this.setState((prevState) => ({
      ...prevState,
      processingMyInfo: true,
      showMyInfoTnc: false,
    }))
    this._preSave({
      ...this._form.state.values,
      showManualEntryPrompt: false,
    })

    API.myInfoBiz.redirect({
      stateId,
    })
  }

  _cancelMyInfoTermsConditions = () => {
    this.setState((prevState) => ({
      ...prevState,
      showMyInfoTnc: false,
    }))
  }

  _myinfoErrHandler = () => {
    const { history, context } = this.props
    const { config } = context
    history.push(`/open-account/${config.application_domain}`)
  }

  _myinfoErrContinueHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      myinfoDataFetching: false,
      myinfoDataSuccess: false,
      myinfoDataError: null,
      myinfoDataErrorPop: false,
      myinfoDataWorkPassError: null,
      myinfoDataWorkPassErrorPop: null,
    }))
    this._preSave({
      ...this._form.state.values,
      showManualEntryPrompt: false,
    })
  }

  _manualEntryPromptCancelHandler = () => {
    const {
      context: { actions },
    } = this.props
    actions.presave({
      showManualEntryPrompt: false,
    })
  }

  _cancelCompanyInactiveHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      isCompanyInactive: false,
    }))
    this._form.setFieldValue('uen', '')
    this._form.setFieldValue('company_name', '')
  }

  _checkIfEligible = (cb, uen) => {
    const { context } = this.props
    const { actions, data } = context
    const { values } = this._form.state

    const nric_roc = uen || values.uen
    const bp_type = '2'

    this._checkTensionValidation(uen)
    actions.checkEligibility(
      {
        nric_roc,
        bp_type,
        occupancy:
          data.premises.premise_occupancy !== '' &&
          data.premises.premise_occupancy,
      },
      cb
    )
  }

  _updateSignupFlow = (normalFlow = false) => {
    const {
      context: { actions, config },
    } = this.props
    const domain = config.application_domain || 'residential'
    let params = {
      addId: [`/open-account/${domain}/company/confirmation`],
      removeId: [
        `/open-account/${domain}/company/review`,
        `/open-account/${domain}/company/upload`,
      ],
    }
    if (normalFlow) {
      params = {
        addId: [
          `/open-account/${domain}/company/review`,
          `/open-account/${domain}/company/upload`,
        ],
        removeId: [`/open-account/${domain}/company/confirmation`],
      }
    }
    actions.updateFlow({
      type: domain,
      under: 'company',
      params,
    })
  }

  _handleSubmit = (values) => {
    const { context } = this.props
    const { actions } = context
    this._preSave({ ...values, showManualEntryPrompt: false })
    actions.next()
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.presave({
      promptMyInfo: !this._form.state.use_myinfo,
      showManualEntryPrompt: false,
    })
    actions.prev()
  }

  _preSave = (values) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {
        company_information: {
          uen: values.uen,
          company_name: values.company_name,
          company_name_source: values.company_name_source,
          ebs_company_name: values.ebs_company_name,
        },
        representative: {
          representative_name: values.representative_name,
          representative_nric_fin: values.representative_nric_fin,
          representative_designation: values.representative_designation,
          representative_mobile_prefix: values.representative_mobile_prefix,
          representative_mobile_number: values.representative_mobile_number,
          representative_contact_prefix: values.representative_contact_prefix,
          representative_contact_number: values.representative_contact_number,
          representative_email: values.representative_email,
          representative_alternate_email: values.representative_alternate_email,
        },
        showManualEntryPrompt: values.showManualEntryPrompt,
      }
      actions.presave(payload)
    }
  }

  _setFields = (data) => {
    Object.keys(data).forEach((d) => {
      this._form.setFieldValue(d, data[d])
    })
  }
}

export default Company
