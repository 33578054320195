import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { object, string, array, boolean } from 'yup'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import {
  FormCTA,
  FormGroup,
  FormChoose,
  FormInput,
  FormSelectOption,
} from 'spd-oa/components/FormElement'
import { Utilities, PremiseForm } from 'spd-oa/components'
import {
  Button,
  ConfirmationDialog,
  Tooltip,
  ReminderDialog,
  Table,
  Tabs,
} from 'spd-oa/components/common'
import { isEmpty, openLink } from 'spd-oa/helpers'
import { API } from 'spd-oa/services'
import Utils from 'spd-oa/utils'
import { tensionErrorContent } from '../../helpers'

const STREETNAME_ERROR_THRESHOLD = 8
const STREETNAME_ERROR__3TIMES = 3

const SecurityDepositForResidentialReminder = () => {
  return (
    <>
      <Tabs.Container defaultActive={`sd-tab-1`}>
        <Tabs.Item id={`sd-tab-1`} label={`Singaporean/PR/Company`}>
          <Table.Container
            data={[
              {
                premises_type: 'HDB 1 or 2 rooms',
                giro: '$40',
                non_giro: '$60',
              },
              {
                premises_type: 'HDB 3, 4 or 5 rooms',
                giro: '$70',
                non_giro: '$100',
              },
              {
                premises_type: 'HDB Executive / HUDC Flat / Terrace House',
                giro: '$100',
                non_giro: '$150',
              },
              {
                premises_type:
                  'Condominum / Semi-Detached House / Private Apartment',
                giro: '$150',
                non_giro: '$250',
              },
              {
                premises_type: 'Bungalow / Penthouse / Townhouse',
                giro: '$250',
                non_giro: '$400',
              },
            ]}
          />
        </Tabs.Item>
        <Tabs.Item id={`sd-tab-2`} label={`Foreigner`}>
          <Table.Container
            data={[
              {
                premises_type: 'HDB 1 or 2 rooms',
                giro: '$80',
                non_giro: '$120',
              },
              {
                premises_type: 'HDB 3, 4 or 5 rooms',
                giro: '$140',
                non_giro: '$200',
              },
              {
                premises_type: 'HDB Executive / HUDC Flat / Terrace House',
                giro: '$200',
                non_giro: '$300',
              },
              {
                premises_type:
                  'Condominum / Semi-Detached House / Private Apartment',
                giro: '$300',
                non_giro: '$500',
              },
              {
                premises_type: 'Bungalow / Penthouse / Townhouse',
                giro: '$500',
                non_giro: '$800',
              },
            ]}
          />
        </Tabs.Item>
      </Tabs.Container>
      <p>
        {`Note: Your initial security deposit will be billed under non-Giro rate in your first bill. Upon successful Giro application, it will be revised and the refunded amount will be used to offset your subsequent bills.`}
      </p>
    </>
  )
}

const SecurityDepositForCommercialReminder = () => {
  return (
    <>
      <Tabs.Container defaultActive={`sd-tab-1`}>
        <Tabs.Item id={`sd-tab-1`} label={`Permanent Supply`}>
          <Table.Container
            respHoriz
            data={[
              {
                use_of_premises: 'HDB Shop Houses',
                water: '$100',
                electricity: '$200',
                gas: '$400',
              },
              {
                use_of_premises: 'Public Lighting',
                water: 'According to SP PowerGrid Quotation',
                electricity: 'According to SP PowerGrid Quotation',
                gas: 'According to SP PowerGrid Quotation',
              },
              {
                use_of_premises: 'All others',
                water: '$3.50 per Cu M or $300 whichever is higher',
                electricity: 'Based on *kVA',
                gas: '$400',
              },
            ]}
          />
          <Table.Container
            data={[
              {
                use_of_premises: 'Stalls - Cooking',
                deposit_required: '$600',
              },
              {
                use_of_premises: 'Stalls - Non Cooking',
                deposit_required: '$200',
              },
              {
                use_of_premises: 'Multi Storey Carpark',
                deposit_required: '$500',
              },
              {
                use_of_premises: 'ATM/Translink/Info Teller',
                deposit_required: '$200',
              },
              {
                use_of_premises: 'Bin Compound',
                deposit_required: '$100',
              },
            ]}
          />
          <small>
            For other premises, initial security deposit is based on the supply
            capacity / electricity load (kVA). If you are not sure of the
            premises electricity load, please check with your licensed
            electrical worker (LEW).
          </small>
          <Table.Container
            data={[
              {
                '*_supply_capacity_(kVA)': 'Up to 15',
                deposit_required: '$450',
              },
              {
                '*_supply_capacity_(kVA)': '16 to 45',
                deposit_required: '$1,350',
              },
              {
                '*_supply_capacity_(kVA)': '46 to 75',
                deposit_required: '$2,100',
              },
              {
                '*_supply_capacity_(kVA)': '76 to 140',
                deposit_required: '$4,050',
              },
              {
                '*_supply_capacity_(kVA)': '141 to 180',
                deposit_required: '$5,100',
              },
              {
                '*_supply_capacity_(kVA)': '181 to 230',
                deposit_required: '$6,600',
              },
              {
                '*_supply_capacity_(kVA)': '231 to 280',
                deposit_required: '$7,950',
              },
              {
                '*_supply_capacity_(kVA)': '281 to 460',
                deposit_required: '$13,050',
              },
              {
                '*_supply_capacity_(kVA)': '461 to 560',
                deposit_required: '$15,900',
              },
              {
                '*_supply_capacity_(kVA)': '561 to 1,000',
                deposit_required: '$28,500',
              },
              {
                '*_supply_capacity_(kVA)': '1,001 and above',
                deposit_required: '$57,000',
              },
            ]}
          />
          <small>Initial Deposit based on Contracted Capacity (kW)</small>
          <Table.Container
            data={[
              {
                '*_supply_capacity_(kW)':
                  'High Tension & Extra High Tension (Temporary & Permanent Supply)',
                deposit_required: '$40 per kW',
              },
            ]}
          />
        </Tabs.Item>
        <Tabs.Item id={`sd-tab-2`} label={`Temporary Supply`}>
          <Table.Container
            respHoriz
            data={[
              {
                use_of_premises: 'Construction Site',
                water: '$3.50 per Cu M or $300 whichever is higher',
                electricity: 'Based on *kVA',
              },
            ]}
          />
          <Table.Container
            data={[
              {
                use_of_premises: 'Site Office',
                deposit_required: '$1,300',
              },
              {
                use_of_premises: 'Wayang',
                deposit_required: '$150 per day',
              },
              {
                use_of_premises: 'Gondola',
                deposit_required: '$200',
              },
            ]}
          />
        </Tabs.Item>
      </Tabs.Container>
      <p>
        If you would like to buy electricity from the Open Electricity Market
        (OEM) via a licensed electricity Retailer or at the wholesale
        electricity price from SP Group after you have opened an utilities
        account with us, your security deposit will be reviewed accordingly and
        reflected in your subsequent utilities bills. Please visit{' '}
        <a href="https://www.openelectricitymarket.sg/home" target="_blank">
          here
        </a>{' '}
        for more information.
      </p>
    </>
  )
}

const reminders = {
  residential: {
    title: 'Security Deposit',
    component: <SecurityDepositForResidentialReminder />,
  },
  commercial: {
    title:
      'Security Deposit <h5>This serves as a guide only. For other types of premises such as public lighting and ATM machines, please consult our Customer Service Officers for your specific requirements.</h5>',
    component: <SecurityDepositForCommercialReminder />,
  },
}

const schema = object().shape({
  application_type: string(),
  application_domain: string(),
  premise_postal_code: string()
    .when('nanl', {
      is: true,
      then: string().notRequired(),
      otherwise: string()
        .required()
        .max(6, 'Postal Code must be 6 digits')
        .min(6, 'Postal Code must be 6 digits')
        .matches(/(^[0-9]+$)/, 'Postal Code must be numeric'),
    })
    .label('Postal Code'),
  premise_block: string()
    .when('nanl', {
      is: true,
      then: string().notRequired(),
      otherwise: string()
        .matches(
          /^[a-zA-Z0-9'@,.()-/]+$/,
          'Please enter valid House / Block No.'
        )
        .required(),
    })
    .label('Block'),
  premise_floor: string()
    .when('nanl', {
      is: true,
      then: string().notRequired(),
      otherwise: string()
        .matches(/^[a-zA-Z0-9]+$/, 'Floor accepts alphabets or numbers')
        .label('Floor'),
    })
    .label('Floor'),
  premise_unit: string()
    .when('nanl', {
      is: true,
      then: string().notRequired(),
      otherwise: string().matches(
        /^[a-zA-Z0-9/]+$/,
        'Unit accepts slash, alphabets and numbers'
      ),
    })
    .label('Unit'),
  premise_street_name: string()
    .when('nanl', {
      is: true,
      then: string().notRequired(),
      otherwise: string()
        .required()
        .matches(/^[\x20-\x7E]*$/, 'Please enter valid Street Name'),
    })
    .max(60, 'The Premises Street name should not be more than 60 characters')
    .label('Street Name'),
  premise_description: string()
    .when('nanl', {
      is: true,
      then: string()
        .required('Please enter your Premises Description')
        .trim('Please enter valid Premises Description')
        .matches(/^[\x20-\x7E]*$/, 'Please enter valid Premises Description')
        .min(10, 'The Premises Description should be more than 10 characters'),
      otherwise: string().notRequired(),
    })
    .max(500, 'The Premises Description should not be more than 500 characters')
    .label('Description'),
  hawker_stall_no: string()
    .matches(
      /^[a-zA-Z0-9-/&]+$/,
      'Hawker Stall No accepts dash, slash, ampersand, alphabets and numbers'
    )
    .label('Hawker Stall No.'),
  premise_occupancy: string()
    .required()
    .label('Type of Occupancy'),
  use_of_premises: string()
    .required()
    .label('Use Of Premises'),
  tension: string().when(['application_domain', 'services', 'enableTension'], {
    is: (domain, services, enableTension) =>
      enableTension &&
      domain === 'commercial' &&
      services.some((s) => s === 'electricity'),
    then: string()
      .required()
      .label('Tension level'),
    otherwise: string().notRequired(),
  }),
  check_same_address: boolean(),
  mail_postal_code: string().when('check_same_address', {
    is: false,
    then: string()
      .required()
      .label('Postal Code')
      .max(6, 'Postal Code must be 6 digits')
      .min(6, 'Postal Code must be 6 digits')
      .matches(/(^[0-9]+$)/, 'Postal Code must be numeric'),
    otherwise: string().notRequired(),
  }),
  mail_block: string().when('check_same_address', {
    is: false,
    then: string()
      .required()
      .label('Mail Block')
      .matches(
        /^[a-zA-Z0-9'@,.()-/]+$/,
        'Please enter valid Mail House / Block'
      ),
    otherwise: string().notRequired(),
  }),
  mail_floor: string().when('check_same_address', {
    is: false,
    then: string().matches(
      /^[a-zA-Z0-9]+$/,
      'Floor accepts alphabets or numbers'
    ),
    otherwise: string().notRequired(),
  }),
  mail_unit: string().when('check_same_address', {
    is: false,
    then: string().matches(
      /^[a-zA-Z0-9/]+$/,
      'Mail Unit accepts slash, alphabets and numbers'
    ),
    otherwise: string().notRequired(),
  }),
  mail_street_name: string().when('check_same_address', {
    is: false,
    then: string()
      .max(60, 'The Mail Street name should not be more than 60 characters')
      .required()
      .label('Mail Street Name'),
    otherwise: string().notRequired(),
  }),
  mail_hawker_stall_no: string().label('Hawker Stall No.'),
  services: array(string()).required('Please choose a utility'),
  type_of_usage: string()
    .when('application_domain', {
      is: 'commercial',
      then: string().required(),
      otherwise: string().notRequired(),
    })
    .label('Type Of Usage'),
  supply_application_number: string()
    .when(['services', 'type_of_usage'], {
      is: (services, typeOfUsage) => {
        return (
          services.some((s) => s === 'electricity') &&
          typeOfUsage === 'temporary'
        )
      },
      then: string()
        .matches(/^[0-9]+$/, 'Supply Application Number should be numbers only')
        .trim()
        .required(),
      otherwise: string().notRequired(),
    })
    .label('Supply Application Number'),
})

class Premises extends PureComponent {
  constructor(props) {
    super(props)
    const { context } = props
    const { config } = context
    const data = context.actions.hydrate({
      premises: {
        premise_address: {
          premise_postal_code: '',
          premise_block: '',
          premise_floor: '',
          premise_unit: '',
          premise_street_name: '',
          premise_country: 'Singapore',
          premise_premise_no: '',
          premise_contestable: '',
          premise_description: '',
        },
        hawker_stall_no: '',
        premise_occupancy: '',
        mail_address: {
          check_same_address: !context.data.isNanl,
          mail_postal_code: '',
          mail_block: '',
          mail_floor: '',
          mail_unit: '',
          mail_street_name: '',
          mail_hawker_stall_no: '',
          mail_country: 'Singapore',
          mail_premise_no: '',
          mail_contestable: '',
        },
        use_of_premises: '',
        nanl: context.data.isNanl,
        tension: '',
      },
      turnOnServices: {
        services:
          config.application_domain === 'residential'
            ? ['electricity', 'water']
            : [],
        type_of_usage: '',
        supply_application_number: '',
      },
    })

    context.actions.setFields(data)
    this.state = {
      data,
      streetNameError: {
        premise: false,
        mail: false,
      },
      streetNameErrorTimes: {
        premise: data.premises.ackn ? 4 : 0,
        mail: 0,
      },
      streetNameStatus: {
        premise: null,
        mail: null,
      },
      streetName: {
        premise: data.premises.nanl,
        mail: false,
        fetching: {
          premise: false,
          mail: false,
        },
      },
      streetNameRetrieveReady: {
        premise: false,
        mail: false,
      },
      streetNameLogic: {
        premise_block: '',
        premise_floor: data.premises.premise_address.premise_floor,
        premise_unit: data.premises.premise_address.premise_unit,
        premise_postal_code: '',
        mail_block: '',
        mail_postal_code: '',
      },
      nanl: data.premises.nanl,
      premisesTryAgain: false,
      turnOnAvailableDates: null,
      ableToFetch: false,
      hasErrorAddress: false,
      confirmRch: false,
      gasPreSelected: false,
      openDocument: false,
      showSD: false,
      reminder: '',
      submitting: false,
      electWithTension: false,
      onlyElectWithTension: false,
    }

    this._debouncedRetrieveStreetName = debounce(
      () => {
        this._retrieveStreetname()
      },
      200,
      {
        trailing: true,
        leading: false,
      }
    )
  }

  componentDidMount() {
    const { data } = this.state
    const { context } = this.props
    context.actions.setFields(data)
    if (this._form) {
      this._form.validateForm()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.streetNameLogic) {
      if (!this.state.isLogged) {
        if (!isEqual(this.state.streetNameLogic, prevState.streetNameLogic)) {
          this._streetNameRetrieveReady(true, this.state._premise)
          this._logged(true)
        }
      }
      this._logged(false)
    }

    if (
      this.state.streetNameErrorTimes['premise'] >= STREETNAME_ERROR__3TIMES
    ) {
      const requiredDocBtn = document.getElementById('required-document')
      const securityDepositeBtn = document.getElementById('security-deposit')

      requiredDocBtn &&
        requiredDocBtn.addEventListener('click', () => {
          this._handleOpenDocument()
        })

      securityDepositeBtn &&
        securityDepositeBtn.addEventListener('click', () => {
          this._handleOpenSD()
        })
    }
  }

  render() {
    const {
      context: { config, data: contextData },
      under,
    } = this.props
    const { mode } = Utils.getOAMode()
    const listUserOfPremise = JSON.parse(
      sessionStorage.getItem('userOfPremise')
    )
    const {
      data,
      streetNameError,
      streetNameStatus,
      streetNameErrorTimes,
      streetName,
      confirmRch,
      openDocument,
      showSD,
      reminder,
      submitting,
      onlyElectWithTension,
      electWithTension,
    } = this.state

    return (
      <div className={`signup--premises signup--form`}>
        <Formik
          ref={(el) => {
            this._form = el
          }}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={this._handleSubmit}
          initialValues={{
            application_domain: config.application_domain,
            application_type: config.application_type,
            ...data.premises.premise_address,
            ...data.premises.mail_address,
            ...data.turnOnServices,
            nanl: data.premises.nanl,
            chilled_water: data.premises.chilled_water,
            premise_occupancy: data.premises.premise_occupancy,
            hawker_stall_no: data.premises.hawker_stall_no,
            check_same_address: data.premises.mail_address
              ? data.premises.mail_address.check_same_address
              : true,
            use_of_premises: data.premises.use_of_premises,
            enableTension: Utils.getFeatureFlag().highTension.enable,
            tension:
              data.premises.tension && data.premises.tension.toLowerCase(),
            // type_of_usage: this._getInitialTypeOfUsage()
          }}
          render={({ handleSubmit, isValid, ...formProps }) => {
            return (
              <form
                className="form-container"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                onSubmit={handleSubmit}
              >
                <PremiseForm
                  title={
                    <div style={{ position: 'relative' }}>
                      <div>Your Premises</div>
                      {!formProps.values.nanl && (
                        <Tooltip
                          style={{
                            position: 'absolute',
                            top: '3px',
                            right: '-25px',
                          }}
                        >
                          Please key in the House / Block No. and Postal Code
                          for the address to be populated
                        </Tooltip>
                      )}
                    </div>
                  }
                  domain={config.application_domain}
                  props={{
                    ...formProps,
                    handleChange: this._handleChange,
                    streetNameError: streetNameError['premise'],
                  }}
                  isPremise={true}
                  streetName={{
                    premise: streetName.premise,
                    fetching: {
                      premise: streetName.fetching.premise,
                    },
                    handleBlur: this._streetNameHandleBlur,
                    status: {
                      premise: streetNameStatus.premise,
                    },
                  }}
                />

                {config.application_domain === 'commercial' &&
                  !formProps.values.nanl && (
                    <FormGroup
                      groupTitle={
                        <div>
                          <div>Hawker Stall No.</div>
                          <small>(if applicable)</small>
                        </div>
                      }
                      groupField={
                        <FormInput
                          label=""
                          id="hawker_stall_no"
                          error={
                            (formProps.touched.hawker_stall_no &&
                              formProps.errors.hawker_stall_no) ||
                            formProps.errors.hawker_stall_no
                          }
                          value={formProps.values.hawker_stall_no}
                          handleChange={(e) => {
                            formProps.handleChange(
                              'hawker_stall_no',
                              e.target.value,
                              'premise'
                            )
                            formProps.setFieldValue(
                              'hawker_stall_no',
                              e.target.value
                            )
                          }}
                          handleBlur={(e) => {
                            if (this._streetNameHandleBlur) {
                              this._streetNameHandleBlur('premise', e)
                            }
                          }}
                          capsOnBlur={true}
                          setFieldValue={formProps.setFieldValue}
                          onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              if (this._streetNameHandleBlur) {
                                this._streetNameHandleBlur('premise', ev) &&
                                  ev.preventDefault()
                              }
                            }
                          }}
                        />
                      }
                    />
                  )}

                <PremiseForm
                  title="Your Mailing Address"
                  domain={config.application_domain}
                  props={{
                    ...formProps,
                    handleChange: this._handleChange,
                    streetNameError: streetNameError['mail'],
                  }}
                  isPremise={false}
                  streetName={{
                    mail: streetName.mail,
                    fetching: {
                      mail: streetName.fetching.mail,
                    },
                    handleBlur: this._streetNameHandleBlur,
                    status: {
                      mail: streetNameStatus.mail,
                    },
                    reset: this._resetStreetName,
                  }}
                />
                <FormGroup
                  groupTitle={`Type Of Occupancy`}
                  groupField={
                    <FormChoose
                      title=""
                      onlyElem={true}
                      selected={[formProps.values['premise_occupancy']]}
                      error={
                        (formProps.touched.premise_owner ||
                          formProps.touched.premise_tenant) &&
                        formProps.errors['premise_occupancy']
                      }
                      setFieldValue={formProps.setFieldValue}
                      setFieldTouched={formProps.setFieldTouched}
                      handleChange={this._chooseOccupancyHandler}
                      choices={[
                        {
                          label: 'Owner',
                          id: 'premise_owner',
                          name: 'premise_occupancy',
                          error: formProps.errors['premise_occupancy'],
                          value: 'owner',
                        },
                        {
                          label: 'Tenant',
                          id: 'premise_tenant',
                          name: 'premise_occupancy',
                          error: formProps.errors['premise_occupancy'],
                          value: 'tenant',
                        },
                      ]}
                    />
                  }
                />
                <FormGroup
                  groupTitle={`Use Of Premises`}
                  groupField={
                    <FormSelectOption
                      // label=""
                      id="use_of_premises"
                      placeholder="Select One"
                      error={
                        formProps.touched.use_of_premises &&
                        formProps.errors.use_of_premises
                      }
                      value={formProps.values.use_of_premises}
                      onChange={formProps.handleChange}
                      handleBlur={formProps.handleBlur}
                      options={[
                        { text: 'Select One', value: '' },
                        ...Utils.getListUserOfPremise(
                          listUserOfPremise,
                          null,
                          config.application_domain === 'commercial'
                            ? '02'
                            : '01'
                        ),
                      ]}
                    />
                  }
                />

                <Utilities
                  domain={config.application_domain}
                  values={{
                    services: formProps.values['services'],
                    // premise_street_name:
                    //   formProps.values['premise_street_name'],
                    // turnOnAvailableDates
                  }}
                  errors={{
                    services:
                      (formProps.touched.services_electricity ||
                        formProps.touched.services_gas ||
                        formProps.touched.services_water) &&
                      formProps.errors['services'],
                  }}
                  setFieldValue={this._chooseUtilitiesHandler}
                  setFieldTouched={formProps.setFieldTouched}
                  handleChange={formProps.handleChange}
                  touched={formProps.touched}
                  company={this.props.under && this.props.under === 'company'}
                  linkHandle={this._rchLinkClickHandler}
                  gasPreSelected={contextData.gasPreSelected}
                  townGas={contextData.townGas}
                />
                {contextData.premises && contextData.premises.chilled_water && (
                  <FormGroup
                    groupTitle={
                      <>
                        {'Additional Service'}
                        <Tooltip>
                          <span>
                            Centralised Cooling Services is included at the
                            premises. This service is provided and managed by SP
                            Home Cooling Pte Ltd.
                          </span>
                        </Tooltip>
                      </>
                    }
                    groupField={
                      <FormChoose
                        id="chilled_water"
                        title={''}
                        onlyElem={true}
                        selected={[formProps.values['chilled_water']]}
                        choices={[
                          {
                            label: 'Centralised Cooling Services',
                            id: 'chilled_water_utility',
                            name: 'chilled_water',
                            value: true,
                            disabled: true,
                          },
                        ]}
                      />
                    }
                  />
                )}
                {Utils.getFeatureFlag().highTension.enable &&
                  config.application_domain === 'commercial' &&
                  formProps.values['services'].some(
                    (s) => s === 'electricity'
                  ) && (
                    <FormGroup
                      groupTitle={'Tension Level'}
                      groupField={
                        <FormSelectOption
                          // label=""
                          id="tension"
                          placeholder="Select One"
                          error={
                            formProps.touched.tension &&
                            formProps.errors.tension
                          }
                          value={formProps.values.tension}
                          onChange={formProps.handleChange}
                          handleBlur={formProps.handleBlur}
                          disabled={contextData.tensionDisabled}
                          options={[
                            { text: 'Select One', value: '' },
                            {
                              text: 'Low Tension',
                              value: 'LT',
                            },
                            {
                              text: 'High Tension',
                              value: 'HT',
                            },
                            {
                              text: 'Extra High Tension',
                              value: 'EHT',
                            },
                            {
                              text: 'Ultra High Tension',
                              value: 'UHT',
                            },
                          ]}
                        />
                      }
                    />
                  )}

                {config.application_domain === 'commercial' && (
                  <FormGroup
                    groupTitle={
                      <>
                        Type Of Usage
                        <Tooltip>
                          <span>
                            Permanent supply are for residential, trade,
                            business or professional use.
                            <br />
                            Temporary supply are for construction, site office,
                            gondola, street opera (e.g. Wayang) use.
                          </span>
                        </Tooltip>
                      </>
                    }
                    groupField={
                      <>
                        <FormChoose
                          onlyElem={true}
                          title=""
                          selected={[formProps.values['type_of_usage']]}
                          error={
                            formProps.touched.type_of_usage &&
                            formProps.errors['type_of_usage']
                          }
                          setFieldValue={formProps.setFieldValue}
                          setFieldTouched={formProps.setFieldTouched}
                          handleChange={formProps.handleChange}
                          choices={[
                            {
                              id: 'type_permanent_supply',
                              label: 'Permanent Supply',
                              value: 'permanent',
                              name: 'type_of_usage',
                              error: formProps.errors['type_of_usage'],
                            },
                            {
                              id: 'type_temporary_supply',
                              label: 'Temporary Supply',
                              value: 'temporary',
                              name: 'type_of_usage',
                              error: formProps.errors['type_of_usage'],
                              disabled: formProps.values['services'].some(
                                (s) => s === 'gas'
                              ),
                            },
                          ]}
                        />
                      </>
                    }
                  >
                    {formProps.values['type_of_usage'] === 'temporary' &&
                      formProps.values['services'].some(
                        (s) => s === 'electricity'
                      ) && (
                        <FormInput
                          label="Supply Application Number"
                          id="supply_application_number"
                          error={
                            formProps.touched.supply_application_number &&
                            formProps.errors.supply_application_number
                          }
                          value={formProps.values.supply_application_number}
                          handleChange={formProps.handleChange}
                          handleBlur={this._handlBlurInput}
                          maxLength={20}
                        />
                      )}
                  </FormGroup>
                )}

                <FormCTA>
                  <Button
                    variant="secondary"
                    onClickHandler={this._handleCancelBtn}
                  >
                    {`Cancel`}
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      (!formProps.nanl &&
                        ((formProps.values.premise_floor !== '' &&
                          formProps.values.premise_unit === '') ||
                          (formProps.values.premise_unit !== '' &&
                            formProps.values.premise_floor === '' &&
                            formProps.values.premise_street_name !== '') ||
                          (!formProps.values.check_same_address &&
                            ((formProps.values.mail_floor !== '' &&
                              formProps.values.mail_unit === '') ||
                              (formProps.values.mail_unit !== '' &&
                                formProps.values.mail_floor === '')) &&
                            formProps.values.mail_street_name !== ''))) ||
                      this.state.streetName.fetching.premise ||
                      !isEmpty(formProps.errors) ||
                      submitting
                    }
                    loading={submitting}
                  >
                    {`Next`}
                  </Button>
                </FormCTA>
              </form>
            )
          }}
        />
        {/* {streetNameErrorTimes['premise'] >= STREETNAME_ERROR_THRESHOLD && (
          <ConfirmationDialog
            id="streetname-prompt"
            confirmOkHandler={this._streetNameErrorMaxHandler}
            content={
              mode === 'kiosk' ? (
                <>
                  The premises address you have entered cannot be found. This
                  could be due to either of the following reasons:
                  <li>Address information is incorrect</li>
                  <li>
                    Non-standard addresses (i.e. Street Light, Bus-Stop, etc)
                  </li>
                  <li>
                    Address details not registered with us (i.e. newly built
                    properties)
                  </li>
                  Please approach our staff for assistance.
                </>
              ) : (
                <>
                  The premises address you have entered cannot be found. This
                  could be due to either of the following reasons:
                  <li>Address information is incorrect</li>
                  <li>
                    Non-standard addresses (i.e. Street Light, Bus-Stop, etc)
                  </li>
                  <li>
                    Address details not registered with us (i.e. newly built
                    properties)
                  </li>
                  To continue with your application, please submit the{' '}
                  <a
                    target="_blank"
                    href="https://www.spgroup.com.sg/wcm/connect/spgrp/1828e33d-f1f1-43d2-98fe-e0ca2b5eb8a0/Application+Form+for+Utilities+Account+v5.0.pdf?MOD=AJPERES"
                  >
                    Application Form
                  </a>{' '}
                  and <a id="required-document">required documents</a> to us via
                  our{' '}
                  <a
                    target="_blank"
                    href="https://eforms.spgroup.com.sg/contactus/contactus.aspx"
                  >
                    'Contact Us'
                  </a>{' '}
                  form available on our website.{' '}
                  <a id="security-deposit"> Security deposit</a> will be billed
                  to your first bill.
                </>
              )
            }
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Go back to open account screen',
                styles: {
                  margin: 'auto',
                },
              },
            }}
          />
        )} */}
        {!this.state.nanl &&
          streetNameErrorTimes['premise'] === STREETNAME_ERROR__3TIMES && (
            <ConfirmationDialog
              id="streetname-prompt"
              confirmOkHandler={this._streetNameErrorMaxOkHandler}
              confirmCancelHandler={this._streetNameErrorMaxCancelHandler}
              content={
                mode === 'kiosk' ? (
                  <>
                    The premises address you have entered cannot be found. This
                    could be due to either of the following reasons:
                    <li>Address information is incorrect</li>
                    <li>
                      Non-standard addresses (i.e. Street Light, Bus-Stop, etc)
                    </li>
                    <li>
                      Address details not registered with us (i.e. newly built
                      properties)
                    </li>
                    <>
                      If you have confirmed the address details and would like
                      to proceed with the account opening, please click
                      'Continue'.
                    </>
                  </>
                ) : (
                  <>
                    The premises address you have entered cannot be found. This
                    could be due to either of the following reasons:
                    <ul>
                      <li>Address information is incorrect</li>
                      <li>
                        Non-standard addresses (i.e. Street Light, Bus-Stop,
                        etc)
                      </li>
                      <li>
                        Address details not registered with us (i.e. newly built
                        properties)
                      </li>
                    </ul>
                    <>
                      If you have confirmed the address details and would like
                      to proceed with the account opening, please click
                      'Continue'.
                    </>
                  </>
                )
              }
              actionConfig={{
                cancel: {
                  label: 'Close',
                },
                ok: {
                  label: 'Continue',
                  styles: {
                    margin: 'none',
                  },
                },
              }}
            />
          )}
        {openDocument && (
          <ReminderDialog
            id="required-document-prompt"
            className="required-document-prompt"
            title={`Document required for account under <span>${under}</span>`}
            content={this._renderRDPromptMessg(
              config.application_domain,
              config.application_type
            )}
            onContinueHandler={this._handleCloseRDPrompt}
            actionConfig={{
              ok: {
                label: 'Close',
                styles: {
                  margin: 'auto',
                  padding: '0 50px',
                },
                handler: this._reminderContinueHandler,
              },
            }}
          />
        )}
        {showSD && (
          <ReminderDialog
            onContinueHandler={this._reminderContinueHandler}
            title={reminders[reminder].title}
            content={reminders[reminder] && reminders[reminder].component}
            actionConfig={{
              ok: {
                label: 'Close',
                handler: this._reminderContinueHandler,
              },
            }}
          />
        )}
        {confirmRch && (
          <ConfirmationDialog
            confirmOkHandler={this._rchLinkConfirmHandler}
            confirmCancelHandler={this._rchLinkConfirmationCloseHandler}
            onCloseHandler={this._rchLinkConfirmationCloseHandler}
            onEscapeKeyDownHandler={this._rchLinkConfirmationCloseHandler}
            content={`You are now leaving this website for a third party website. Please review the new site's privacy and security policies as they may differ from those of this site.`}
            actionConfig={{
              cancel: {
                label: 'Cancel',
              },
              ok: {
                label: 'OK',
              },
            }}
          />
        )}

        {onlyElectWithTension && (
          <ConfirmationDialog
            id="only-elect-tension-err-prompt"
            confirmCancelHandler={this._streetNameErrorMaxCancelHandler}
            content={tensionErrorContent()}
            processing={false}
            actionConfig={{
              cancel: {
                label: 'Go back to open account screen',
              },
              ok: false,
            }}
          />
        )}
        {electWithTension && (
          <ConfirmationDialog
            id="elect-tension-err-prompt"
            confirmOkHandler={this._removeElectrictWithTension}
            content={tensionErrorContent(true)}
            processing={false}
            actionConfig={{
              cancel: false,
              ok: {
                label: 'Continue',
              },
            }}
          />
        )}
      </div>
    )
  }

  _handleOpenDocument = () => {
    this.setState({ openDocument: true })
  }

  _handleCloseRDPrompt = () => {
    this.setState({ openDocument: false })
  }

  _handleOpenSD = (e) => {
    const {
      context: { config },
    } = this.props

    this.setState((prevState) => ({
      ...prevState,
      reminder: config.application_domain,
      showSD: true,
    }))
  }

  _reminderContinueHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      reminder: '',
      showSD: false,
    }))
  }

  _renderRDPromptMessg = (domain, type) => {
    if (domain === 'residential' && type === 'personal') {
      return (
        <ul className="document-message">
          <li>Completed and signed Application Form for Utilities Account</li>
          <li>
            Copy of NRIC/FIN (Front and Back)
            <br />
            (For Foreign Delegates, please provide copy of Green Card/Employment
            Pass/ copy of Exemption Order.)
          </li>
          <li>
            Documentary proof of occupancy of premises
            <br />
            (e.g. Tenancy Agreement or property tax)
          </li>
          <li>
            Certificate of Numbering issued by IRAS (for new premises only).
          </li>
        </ul>
      )
    }

    if (domain === 'residential' && type === 'company') {
      return (
        <ul className="document-message">
          <li>Completed and signed Application Form for Utilities Account</li>
          <li>
            Letter of Authorisation
            <br />
            Notes: To be authorised by Director listed in the recent ACRA
            detailed Business Profile/Bizfile on the letterhead issued by
            company, indicating the company's representative's name and
            identification number, i.e. NRIC or FIN. (Required if signatory is
            not a Director according to ACRA listing.)
          </li>
          <li>
            Documentary proof of occupancy of premises
            <br />
            (e.g. Tenancy Agreement or property tax)
          </li>
          <li>
            Certificate of Numbering issued by IRAS (for new premises only).
          </li>
        </ul>
      )
    }

    if (domain === 'commercial' && type === 'personal') {
      return (
        <ul className="document-message">
          <li>Completed and signed Application Form for Utilities Account</li>
          <li>
            Copy of NRIC/FIN (Front and Back)
            <br />
            (For Foreign Delegates, please provide copy of Green Card/Employment
            Pass/ copy of Exemption Order.)
          </li>
          <li>
            Documentary proof of occupancy of premises
            <br />
            (e.g. Tenancy Agreement or property tax)
          </li>
          <li>
            Copy of completed and signed Acknowledgement of Electrical
            Installation Licence Requirement Form (EIL Form), if the electricity
            load is above 45 kVA. For more information on the licence, please
            refer to www.ema.gov.sg
          </li>
          <li>
            For temporary supply of electricity, the
            <Tooltip
              withIcon={false}
              style={{
                marginLeft: '0.2rem',
                marginRight: '0.2rem',
              }}
              label="Quotation"
            >
              For temporary supply of electricity, please engage a Licensed
              Electrical Worker (LEW) to submit the supply application to SP
              Services Ltd. A Quotation for Electricity Supply Connection will
              be issued and the applicant is required to bring this Quotation
              and LEI issued by EMA to apply for temporary supply of
              electricity.
            </Tooltip>
            for Electricity Supply Connection and LEI issued by EMA are
            required.
          </li>
          <li>
            For temporary supply of water, a Water
            <Tooltip
              withIcon={false}
              style={{
                marginLeft: '0.2rem',
                marginRight: '0.2rem',
              }}
              label="Quotation"
            >
              For temporary supply of water, please engage a Licensed Water
              Service Plumber to submit the Notification of Water Service Work
              Form to PUB and submit the Water Quotation received from PUB
              thereafter to SP Services during the utilities application
            </Tooltip>
            from PUB is required.
          </li>
          <li>
            Certificate of Numbering issued by IRAS (for new premises only).
          </li>
        </ul>
      )
    }

    if (domain === 'commercial' && type === 'company') {
      return (
        <ul className="document-message">
          <li>Completed and signed Application Form for Utilities Account</li>
          <li>
            Letter of Authorisation
            <br />
            Notes: To be authorised by Director listed in the recent ACRA
            detailed Business Profile/Bizfile on the letterhead issued by
            company, indicating the company's representative's name and
            identification number, i.e. NRIC or FIN. (Required if signatory is
            not a Director according to ACRA listing.)
          </li>
          <li>Latest ACRA detailed Business Profile/Bizfile</li>
          <li>
            Documentary proof of occupancy of premises
            <br />
            (e.g. Tenancy Agreement or property tax)
          </li>
          <li>
            Copy of completed and signed Acknowledgement of Electrical
            Installation Licence Requirement Form (EIL Form), if the electricity
            load is above 45 kVA. For more information on the licence, please
            refer to www.ema.gov.sg
          </li>
          <li>
            For temporary supply of electricity, the
            <Tooltip
              withIcon={false}
              style={{
                marginLeft: '0.2rem',
                marginRight: '0.2rem',
              }}
              label="Quotation"
            >
              For temporary supply of electricity, please engage a Licensed
              Electrical Worker (LEW) to submit the supply application to SP
              Services Ltd. A Quotation for Electricity Supply Connection will
              be issued and the applicant is required to bring this Quotation
              and LEI issued by EMA to apply for temporary supply of
              electricity.
            </Tooltip>
            for Electricity Supply Connection and LEI issued by EMA are
            required.
          </li>
          <li>
            For temporary supply of water, a Water
            <Tooltip
              withIcon={false}
              style={{
                marginLeft: '0.2rem',
                marginRight: '0.2rem',
              }}
              label="Quotation"
            >
              For temporary supply of water, please engage a Licensed Water
              Service Plumber to submit the Notification of Water Service Work
              Form to PUB and submit the Water Quotation received from PUB
              thereafter to SP Services during the utilities application
            </Tooltip>
            from PUB is required.
          </li>
          <li>
            Certificate of Numbering issued by IRAS (for new premises only).
          </li>
        </ul>
      )
    }
  }

  _getInitialTypeOfUsage = () => {
    const { context } = this.props
    const { config } = context
    if (this._form) {
      if (config.application_domain === 'commercial') {
        const {
          state: { values },
        } = this._form
        if (values['services']) {
          if (values['services'].some((s) => s === 'gas')) {
            return 'permanent'
          } else {
            return []
          }
        }
      }
    }
    return []
  }

  _chooseUtilitiesHandler = (id, value) => {
    const { context } = this.props
    const { config, data: contextData, actions } = context
    const { setFieldValue } = this._form

    if (config.application_domain === 'commercial') {
      if (value.length > 0) {
        if (value.some((s) => s === 'gas')) {
          setFieldValue('type_of_usage', 'permanent')
        }
        if (
          Utils.getFeatureFlag().highTension.enable &&
          value.some((s) => s === 'electricity')
        ) {
          if (context.data.tensionRetrieval) {
            setFieldValue(
              'tension',
              context.data.tensionRetrieval.toLowerCase()
            )
          }
        }
      }
      if (contextData.security_deposit_calculation) {
        actions.presave({
          security_deposit_calculation: {
            ...contextData.security_deposit_calculation,
            security_deposit: 0,
            USE_OF_PREMISES_LIST: '',
            selected_premises: '',
            ELECT_CAPACITY: '',
            WATER_CAPACITY: '',
            selected_elec_cap: undefined,
          },
        })
      }
    } else {
      if (
        context.data.gasPreSelected &&
        id === 'services' &&
        !value.some((svc) => svc === 'gas')
      ) {
        context.actions.presave({ ...context.data, isPooRequired: true })
      } else {
        context.actions.presave({ ...context.data, isPooRequired: false })
      }
    }
    setFieldValue(id, value)
  }

  _chooseOccupancyHandler = (e) => {
    const { context } = this.props
    const {
      actions: contextActions,
      data: contextData,
      config: contextConfig,
    } = context

    const { value } = e.target

    const nric_roc =
      contextData.personal_information &&
      contextData.personal_information.nric_fin
    const bp_type = contextConfig.application_type === 'personal' ? '1' : '2'

    contextActions.checkEligibility({
      nric_roc,
      bp_type,
      occupancy: value,
    })

    this._form.handleChange(e)
  }

  _checkStreetNameLogic(field, value, premise) {
    const { streetNameLogic } = this.state
    const { streetNameLogicFields } = this.props
    const { values } = this._form.state

    // Checks if floor/Unit were keyed in, should be both
    if (
      (streetNameLogic[`${premise}_floor`] &&
        streetNameLogic[`${premise}_floor`] !== '' &&
        (!streetNameLogic[`${premise}_unit`] ||
          streetNameLogic[`${premise}_unit`] === '')) ||
      (values[`${premise}_floor`] &&
        values[`${premise}_floor`] !== '' &&
        values[`${premise}_unit`] &&
        values[`${premise}_unit`] === '')
    ) {
      return false
    }

    if (
      (streetNameLogic[`${premise}_unit`] &&
        streetNameLogic[`${premise}_unit`] !== '' &&
        (!streetNameLogic[`${premise}_floor`] ||
          streetNameLogic[`${premise}_floor`] === '')) ||
      (values[`${premise}_unit`] &&
        values[`${premise}_unit`] !== '' &&
        values[`${premise}_floor`] &&
        values[`${premise}_floor`] === '')
    ) {
      return false
    }

    // Block and Postal Code are compumsory fields, and postal code must be of length 6
    if (
      streetNameLogic[`${premise}_block`] !== '' ||
      (values[`${premise}_block`] !== '' &&
        streetNameLogic[`${premise}_postal_code`] &&
        streetNameLogic[`${premise}_postal_code`] !== '') ||
      (values[`${premise}_postal_code`] &&
        values[`${premise}_postal_code`] !== '')
    ) {
      if (
        (streetNameLogic[`${premise}_postal_code`] &&
          streetNameLogic[`${premise}_postal_code`].length === 6) ||
        (values[`${premise}_postal_code`] &&
          values[`${premise}_postal_code`].length === 6)
      ) {
        return true
      }
    }
    return false
  }

  _streetNameErrorMaxOkHandler = () => {
    const { context } = this.props
    const { actions, data } = context

    this.setState((prevState) => ({
      ...prevState,
      nanl: true,
    }))
    actions.presave({ premises: { ...data.premises, nanl: true } })
    this._form.setFieldValue('nanl', true)
    this._form.setFieldValue('premise_postal_code', '')
    this._form.setFieldValue('premise_block', '')
    this._form.setFieldValue('premise_floor', '')
    this._form.setFieldValue('premise_unit', '')
    this._form.setFieldValue('premise_street_name', '')
    this._form.setFieldValue('check_same_address', false)
    window.scrollTo(0, 0)
  }

  _streetNameErrorMaxCancelHandler = () => {
    const { history, context } = this.props
    const { config } = context
    Utils.clearSession()
    history.push(`/open-account/${config.application_domain}`)
  }

  _retrieveStreetname() {
    const {
      context: { config, data, actions },
    } = this.props
    const { streetNameLogic } = this.state
    const {
      state: { values, touched },
      setFieldValue,
      setTouched,
    } = this._form

    const isForPremises = () => {
      return this._premise === 'premise'
    }

    this.setState((prevState) => ({
      ...prevState,
      streetName: {
        ...prevState.streetName,
        fetching: {
          ...prevState.streetName.fetching,
          [this._premise]: true,
        },
      },
    }))

    const isEnterPremisesAddress =
      (touched.premise_postal_code ||
        touched.premise_block ||
        touched.premise_floor ||
        touched.premise_unit) &&
      (!touched.mail_block || !touched.mail_postal_code)

    API.retrieveStreetName({
      postcode: values[`${this._premise}_postal_code`],
      block: values[`${this._premise}_block`],
      unit: values[`${this._premise}_unit`],
      floor: values[`${this._premise}_floor`],
      for_premises: `${this._premise}` === 'premise',
      utilities_flag: isEnterPremisesAddress ? 1 : 0,
    })
      .then((res) => {
        setTouched({})
        this.setState(
          (prevState) => ({
            ...prevState,
            streetNameError: {
              ...prevState.streetNameError,
              [this._premise]: false,
            },
            streetNameErrorTimes: {
              ...prevState.streetNameErrorTimes,
              [this._premise]:
                config.application_domain === 'commercial'
                  ? prevState.streetNameErrorTimes[this._premise]
                  : 0,
            },
            streetNameStatus: {
              ...prevState.streetNameStatus,
              [this._premise]: 'success',
            },
          }),
          () => {
            if (config.application_domain === 'residential' && res.utilities) {
              if (
                parseInt(res.utilities.town_gas) ||
                (res.utilities.town_gas === '0' && res.utilities.gas)
              ) {
                setFieldValue('services', [...values.services, 'gas'])
              }

              if (!parseInt(res.utilities.town_gas) && !res.utilities.gas) {
                setFieldValue('services', ['electricity', 'water'])
              }
            }

            if (res.street_name === '') {
              this.setState((prevState) => ({
                ...prevState,
                streetName: {
                  ...prevState.streetName,
                  [this._premise]: true,
                  fetching: {
                    ...prevState.streetName.fetching,
                    [this._premise]: false,
                  },
                },
              }))
              if (res.utilities) {
                const tensionLevel = Utils.getFeatureFlag().highTension.enable
                  ? res.utilities.tensionLevel &&
                    res.utilities.tensionLevel.toLowerCase()
                  : ''
                actions.presave({
                  ...data,
                  gasPreSelected: res.utilities && res.utilities.gas,
                  tensionDisabled: !!res.utilities.tensionLevel,
                  tensionRetrieval: res.utilities.tensionLevel,
                  townGas:
                    res.utilities.town_gas === ''
                      ? '0'
                      : res.utilities.town_gas,
                  premises: {
                    ...data.premises,
                    chilled_water: res.utilities.chilledWater,
                    tension: tensionLevel,
                  },
                })
                setFieldValue('chilled_water', res.utilities.chilledWater)
                setFieldValue('tension', tensionLevel || '')
              } else {
                actions.presave({
                  ...data,
                  tensionDisabled: false,
                  premises: {
                    ...data.premises,
                    chilled_water: false,
                    tension: '',
                  },
                })
                setFieldValue('chilled_water', false)
                setFieldValue('tension', '')
              }
              this._form.setFieldValue(
                `${this._premise}_street_name`,
                res.street_name
              )
            } else {
              this.setState((prevState) => ({
                ...prevState,
                streetName: {
                  ...prevState.streetName,
                  [this._premise]: false,
                  fetching: {
                    ...prevState.streetName.fetching,
                    [this._premise]: false,
                  },
                },
              }))

              if (isForPremises()) {
                actions.generateNotification(true)
                actions.presave({
                  ...data,
                  terminateDate: res.terminate_date,
                  premises: {
                    ...data.premises,
                  },
                })

                if (res.utilities) {
                  const tensionLevel = Utils.getFeatureFlag().highTension.enable
                    ? res.utilities.tensionLevel &&
                      res.utilities.tensionLevel.toLowerCase()
                    : ''
                  actions.presave({
                    ...data,
                    gasPreSelected: res.utilities && res.utilities.gas,
                    townGas:
                      res.utilities.town_gas === ''
                        ? '0'
                        : res.utilities.town_gas,
                    isPooRequired: false,
                    tensionDisabled: !!res.utilities.tensionLevel,
                    tensionRetrieval: res.utilities.tensionLevel,
                    premises: {
                      ...data.premises,
                      chilled_water: res.utilities.chilledWater,
                      tension: tensionLevel,
                    },
                    terminateDate: res.terminate_date,
                  })
                  setFieldValue('tension', tensionLevel || '')
                  setFieldValue('chilled_water', res.utilities.chilledWater)
                } else {
                  actions.presave({
                    ...data,
                    tensionDisabled: false,
                    premises: {
                      ...data.premises,
                      chilled_water: false,
                      tension: '',
                    },
                    terminateDate: res.terminate_date,
                  })
                  setFieldValue('tension', '')
                  setFieldValue('chilled_water', false)
                }
              }

              this._form.setFieldValue(
                `${this._premise}_street_name`,
                res.street_name
              )
              this._form.setFieldValue(
                `${this._premise}_premise_no`,
                res.premise_no
              )
              this._form.setFieldValue(
                `${this._premise}_contestable`,
                res.contestable
              )
            }
            this._form.state.errors = {}
            if (this._premise === 'premise') {
              setFieldValue('nanl', false)
            }
          }
        )
      })
      .catch((err) => {
        setTouched({})
        this.setState((prevState) => ({
          ...prevState,
          streetNameError: {
            ...prevState.streetNameError,
            [this._premise]: err.message,
          },
          streetNameErrorTimes: {
            ...prevState.streetNameErrorTimes,
            [this._premise]: prevState.streetNameErrorTimes[this._premise] + 1,
          },
          streetNameStatus: {
            ...prevState.streetNameStatus,
            [this._premise]: 'error',
          },
          streetName: {
            ...prevState.streetName,
            [this._premise]: false,
            fetching: {
              ...prevState.streetName.fetching,
              [this._premise]: false,
            },
          },
        }))
        this._form.setFieldValue(`${this._premise}_street_name`, '')
      })
  }

  _checkTensionValidation = (values) => {
    const { context } = this.props
    const { config, actions: contextActions, data: contextData } = context
    const uen =
      contextData &&
      contextData.company_information &&
      contextData.company_information.uen
    const nric_roc = uen

    if (config.application_type === 'company' && nric_roc) {
      const payload = {
        tensionLevel: values.tension.toUpperCase(),
        nric: nric_roc,
        electricitySelected: true,
        waterSelected: values && values.services.includes('water'),
        gasSelected: values && values.services.includes('gas'),
        chilledWaterSelected: values && values.chilled_water,
      }
      this.setState((prevState) => ({ ...prevState, submitting: true }))
      API.tensionValidation(payload)
        .then((res) => {
          contextActions.presave({
            mcst: res.mcst,
          })
          if (!res.mcst) {
            if (values.services.length === 1 && !values.chilled_water) {
              this.setState((prevState) => ({
                ...prevState,
                onlyElectWithTension: true,
              }))
            } else {
              this.setState((prevState) => ({
                ...prevState,
                electWithTension: true,
              }))
            }
          } else {
            this._handleNext(values)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.setState((prevState) => ({ ...prevState, submitting: false }))
        })
    } else if (config.application_type === 'personal') {
      if (values.services.length === 1 && !values.chilled_water) {
        this.setState((prevState) => ({
          ...prevState,
          onlyElectWithTension: true,
        }))
      } else {
        this.setState((prevState) => ({
          ...prevState,
          electWithTension: true,
        }))
      }
    }
  }

  _removeElectrictWithTension = () => {
    this.setState((prevState) => ({
      ...prevState,
      electWithTension: false,
    }))
    const values = this._form.state.values
    values.services = values.services.filter((sv) => sv !== 'electricity')
    values.tension = ''
    this._handleNext(values)
  }

  _handleSubmit = async (values) => {
    const { config, data } = this.props.context

    if (
      config.application_domain === 'commercial' &&
      ((config.application_type === 'company' &&
        data.company_information &&
        data.company_information.uen) ||
        config.application_type === 'personal') &&
      values.services.includes('electricity') &&
      values.tension &&
      values.tension !== 'lt'
    ) {
      this._checkTensionValidation(values)
    } else {
      this._handleNext(values)
    }
  }

  _handleNext = (values) => {
    const { context } = this.props
    const { actions } = context
    if (values.type_of_usage === 'permanent') {
      this._preSave({ ...values, supply_application_number: '' })
    } else {
      this._preSave(values)
    }
    actions.next()
  }

  _handleBackBtn = () => {
    const { context } = this.props
    const { actions } = context
    if (this._form.state && this._form.state.values) {
      this._preSave(this._form.state.values)
    }
    actions.prev()
  }

  _handleCancelBtn = () => {
    const { context } = this.props
    const { actions } = context
    actions.cancel()
  }

  _handleChange = (field, value, premise) => {
    const { streetNameLogicFields } = this.props
    this.setState(
      (prevState) => {
        let snl = {}
        streetNameLogicFields.forEach((f) => {
          if (`${field}` === `${premise}_${f}`) {
            snl[field] = value
          }
        })
        return {
          ...prevState,
          streetNameLogic: {
            ...prevState.streetNameLogic,
            ...snl,
          },
        }
      },
      () => {
        let streetNameRetrieveReady = this._checkStreetNameLogic(
          field,
          value,
          premise
        )
        if (streetNameRetrieveReady) {
          if (premise) {
            this._premise = premise
            this.setState((prevState) => ({
              ...prevState,
              streetNameRetrieveReady: {
                ...prevState.streetNameRetrieveReady,
                [this._premise]: true,
              },
            }))
          }
        } else {
          if (this._premise) {
            this.setState((prevState) => ({
              ...prevState,
              streetNameRetrieveReady: {
                ...prevState.streetNameRetrieveReady,
                [this._premise]: false,
              },
            }))
          }
        }
      }
    )
  }

  _preSave = (values) => {
    const { actions } = this.props.context
    if (actions.presave) {
      const payload = {}
      payload.premises = {
        premise_address: {
          premise_postal_code: values.premise_postal_code,
          premise_block: values.premise_block,
          premise_floor: values.premise_floor,
          premise_unit: values.premise_unit,
          premise_street_name: values.premise_street_name,
          premise_country: values.premise_country,
          premise_premise_no: values.premise_premise_no,
          premise_description: values.premise_description,
        },
        hawker_stall_no: values.hawker_stall_no,
        premise_occupancy: values.premise_occupancy,
        use_of_premises: values.use_of_premises,
        chilled_water: !!values.chilled_water,
        nanl: values.nanl,
        tension: values.tension && values.tension.toUpperCase(),
      }
      if (values.check_same_address) {
        payload.premises = {
          ...payload.premises,
          mail_address: {
            check_same_address: values.check_same_address,
            mail_postal_code: values.premise_postal_code,
            mail_block: values.premise_block,
            mail_floor: values.premise_floor,
            mail_unit: values.premise_unit,
            mail_street_name: values.premise_street_name,
            mail_hawker_stall_no: values.mail_hawker_stall_no,
            mail_country: values.premise_country,
          },
        }
      } else {
        payload.premises = {
          ...payload.premises,
          mail_address: {
            check_same_address: values.check_same_address,
            mail_postal_code: values.mail_postal_code,
            mail_block: values.mail_block,
            mail_floor: values.mail_floor,
            mail_unit: values.mail_unit,
            mail_street_name: values.mail_street_name,
            mail_hawker_stall_no: values.mail_hawker_stall_no,
            mail_country: values.mail_country,
          },
        }
      }
      payload['turnOnServices'] = {
        services: values.services,
        chilled_water: !!values.chilled_water,
        type_of_usage: values.type_of_usage,
        turn_on_date: values.turn_on_date,
        supply_application_number: values.supply_application_number,
      }
      actions.presave(payload)
    }
  }

  _handlBlurInput = (e) => {
    this._form.setFieldTouched(e.target.id, true)
  }

  _streetNameHandleBlur = (premise, e) => {
    this._handlBlurInput(e)
    const { streetNameRetrieveReady } = this.state
    if (streetNameRetrieveReady[this._premise] && this.state.ableToFetch) {
      this._form.setFieldValue(`${this._premise}_street_name`, '')
      this._debouncedRetrieveStreetName()
      this.setState({ ableToFetch: false })
    }
  }

  _rchLinkClickHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmRch: true,
    }))
  }
  _rchLinkConfirmationCloseHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmRch: false,
    }))
  }
  _rchLinkConfirmHandler = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        confirmRch: false,
      }),
      () => {
        openLink('https://www.cityenergy.com.sg', '_blank')
      }
    )
  }

  _resetStreetName = () => {
    this.setState((prevState) => ({
      ...prevState,
      streetNameLogic: '',
      streetNameError: {
        ...prevState.streetNameError,
        mail: false,
      },
      streetNameErrorTimes: {
        ...prevState.streetNameErrorTimes,
        mail: 0,
      },
      streetNameRetrieveReady: {
        ...prevState.streetNameRetrieveReady,
        mail: false,
      },
    }))
  }

  _streetNameRetrieveReady = (ev, premise) => {
    this.setState({ ableToFetch: true })
  }

  _logged = (ev) => {
    this.setState({
      isLogged: ev,
    })
  }

  _hasErrorAddress = () => {
    const addressFields = [
      'premise_unit',
      'premise_floor',
      'premise_block',
      'premise_postal_code',
      'mail_unit',
      'mail_floor',
      'mail_block',
      'mail_postal_code',
    ]
    const errors = Object.keys(this._form.state.errors)
    const collectionErrors = []
    addressFields.forEach((a) =>
      errors.forEach((b) => {
        if (a === b) {
          collectionErrors.push(a)
        }
      })
    )
    return !isEmpty(collectionErrors)
  }
}

Premises.defaultProps = {
  streetNameLogicFields: ['postal_code', 'block', 'unit', 'floor'],
}

Premises.propTypes = {
  streetNameLogicFields: PropTypes.array,
}

export default Premises
